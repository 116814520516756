import styled, { css } from 'styled-components';

import { colors, fonts, radii, scrollbar } from '@gbs-monorepo-packages/styles';

import { Button as CommonButton } from '../Button';

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
`;

export const FormContent = styled.div`
  margin: 1rem auto;
`;

interface IThemeStyleProps {
  themeStyle?: 'admin' | 'default';
}

const adminStyle = css`
  font-family: ${fonts.admin};
  font-weight: 600;
`;

export const Title = styled.h1<IThemeStyleProps>`
  font-size: 1.125rem;
  margin-bottom: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  ${({ themeStyle = 'default' }) =>
    themeStyle === 'admin' &&
    css`
      ${adminStyle}
    `}
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  & button + button {
    margin-left: 1rem;
  }
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  max-height: 5rem;

  overflow-y: auto;

  ${scrollbar}
`;

export const ErrorMessage = styled.p`
  border: ${radii.xs};
  color: ${colors.red300};
  font-size: 0.875rem;
`;

export const Button = styled(CommonButton)<IThemeStyleProps>`
  ${({ themeStyle = 'default' }) =>
    themeStyle === 'admin' &&
    css`
      background-color: ${colors.blue275};
      color: ${colors.white300};

      &:hover {
        background-color: ${colors.blue300};
      }
    `}
`;
