import { type Property } from 'csstype';
import styled from 'styled-components';

export interface ITableCellProps {
  readonly $alignItems?: Property.AlignItems;
}

export const TableStatusCellContainer = styled.div<ITableCellProps>`
  align-items: ${({ $alignItems }) => $alignItems ?? 'flex-start'};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: center;
  padding: 0.5rem;
`;
