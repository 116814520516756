import { Roles } from '../constants/Roles';

export interface IRole {
  key: Roles;
  value: string;
  level: number;
}

export const translateUserRoleInRole: Record<string, IRole> = {};
translateUserRoleInRole[Roles.ADMIN] = {
  key: Roles.ADMIN,
  value: 'Admin',
  level: 5,
};
translateUserRoleInRole[Roles.BROKER_CONSULTANT] = {
  key: Roles.BROKER_CONSULTANT,
  value: 'Broker/Consultant',
  level: 4,
};
translateUserRoleInRole[Roles.RENEWAL_COORDINATOR] = {
  key: Roles.RENEWAL_COORDINATOR,
  value: 'Renewal Coordinator',
  level: 4,
};
translateUserRoleInRole[Roles.RENEWAL_ANALYST] = {
  key: Roles.RENEWAL_ANALYST,
  value: 'Renewal Analyst',
  level: 4,
};
translateUserRoleInRole[Roles.ACCOUNT_MANAGER] = {
  key: Roles.ACCOUNT_MANAGER,
  value: 'Account Manager',
  level: 4,
};
translateUserRoleInRole[Roles.CLIENT_MANAGER] = {
  key: Roles.CLIENT_MANAGER,
  value: 'Client Manager',
  level: 4,
};
translateUserRoleInRole[Roles.ACCOUNT_SUPPORT] = {
  key: Roles.ACCOUNT_SUPPORT,
  value: 'Account Support',
  level: 4,
};
translateUserRoleInRole[Roles.CLIENT] = {
  key: Roles.CLIENT,
  value: 'Client',
  level: 3,
};
translateUserRoleInRole[Roles.EMPLOYEE] = {
  key: Roles.EMPLOYEE,
  value: 'Employee',
  level: 1,
};
