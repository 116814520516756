import { SlCloudUpload } from 'react-icons/sl';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

interface IDropzoneProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  isFocused: boolean;
}

export const Container = styled.div<IDropzoneProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: ${radii.lg};
  border-color: ${(props: IDropzoneProps) => {
    if (props.isDragAccept) {
      return colors.dropzoneBorderSuccess;
    }
    if (props.isDragReject) {
      return colors.dropzoneBorderError;
    }
    if (props.isFocused) {
      return colors.dropzoneBorderFocus;
    }
    return colors.dropzoneBorder;
  }};
  border-style: dashed;
  background-color: ${colors.dropzoneBackground};
  color: ${colors.dropzoneText};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const UploadIcon = styled(SlCloudUpload)`
  width: 2rem;
  height: 2rem;
  margin-bottom: 10px;
  color: ${colors.textLight};
`;
