import { type ReactNode, type RefAttributes, forwardRef } from 'react';

import { type AvatarImageProps } from '@radix-ui/react-avatar';

import { Container, Fallback, Image, NoImage } from './styles';

export interface ILazyImageProps
  extends AvatarImageProps,
    RefAttributes<HTMLImageElement> {
  alt?: string;
  children?: ReactNode;
  dataCy?: string;
  delayMs?: number;
}

export const LazyImage = forwardRef<HTMLImageElement, ILazyImageProps>(
  // eslint-disable-next-line no-restricted-syntax
  function LazyImage(
    { alt, children, dataCy, delayMs = 500, ...props },
    ref
  ): JSX.Element {
    return (
      <Container>
        <Image {...props} ref={ref} alt={alt} data-cy={dataCy} />
        <Fallback
          {...props}
          ref={ref}
          data-cy="image-notFound"
          delayMs={delayMs}
        >
          {children ?? <NoImage data-cy={dataCy} />}
        </Fallback>
      </Container>
    );
  }
);
