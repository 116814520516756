import { type ReactNode, useState } from 'react';

import * as Collapsible from '@radix-ui/react-collapsible';

import {
  BaseItemContainer,
  BaseItemText,
  BaseItemTextContainer,
  BaseItemTriggerContainer,
  CollapseMenuIcon,
  ExpandButton,
  ExpandMenuIcon,
} from './styles';

interface IBaseCollapsibleMenuProps {
  scrollToBottomFunction?: () => void;
  children: ReactNode;
  baseItemText: string;
}

export const BaseCollapsibleMenu = ({
  baseItemText,
  children,
  scrollToBottomFunction,
}: IBaseCollapsibleMenuProps): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <BaseItemContainer>
        <BaseItemTextContainer>
          <BaseItemText>{baseItemText}</BaseItemText>
        </BaseItemTextContainer>
        <BaseItemTriggerContainer>
          <Collapsible.Trigger asChild>
            <ExpandButton
              onClick={() =>
                setTimeout(() => {
                  scrollToBottomFunction?.();
                }, 100)
              }
            >
              {open ? <CollapseMenuIcon /> : <ExpandMenuIcon />}
            </ExpandButton>
          </Collapsible.Trigger>
        </BaseItemTriggerContainer>
      </BaseItemContainer>

      <Collapsible.Content>{children}</Collapsible.Content>
    </Collapsible.Root>
  );
};
