import { type CSSProperties, type ReactNode, type Ref } from 'react';

import {
  Close,
  DialogPortal,
  type DialogProps,
  Root,
  Trigger,
} from '@radix-ui/react-dialog';

import { CloseButton, CloseIcon, DialogContent, DialogOverlay } from './styles';

type PointerDownOutsideEvent = CustomEvent<{
  originalEvent: PointerEvent;
}>;
type FocusOutsideEvent = CustomEvent<{
  originalEvent: FocusEvent;
}>;

type InteractOutsideEvent = FocusOutsideEvent | PointerDownOutsideEvent;

export interface IBaseModalProps extends DialogProps {
  biggerModal?: boolean;
  children: ReactNode;
  dataCy?: string;
  hiddenCloseButton?: boolean;
  refContent?: Ref<HTMLDivElement>;
  sideModal?: boolean;
  fullHeight?: boolean;
  setWidth?: string;
  trigger?: ReactNode;
  zIndex?: number;
  className?: string;
  style?: CSSProperties;
  vhHeight?: string;
  fullWidth?: boolean;
  loading?: boolean;
}

export const BaseModal = ({
  biggerModal = false,
  children,
  className,
  dataCy = 'content-modal',
  hiddenCloseButton = false,
  refContent,
  sideModal = false,
  fullHeight = false,
  style,
  trigger,
  zIndex,
  setWidth,
  loading = false,
  vhHeight,
  fullWidth = false,
  ...props
}: IBaseModalProps): JSX.Element => {
  const onInteractOutside = (event: InteractOutsideEvent) => {
    if (!loading) return;

    event.preventDefault();
  };

  return (
    <Root {...props}>
      <Trigger asChild>{trigger}</Trigger>

      <DialogPortal data-cy="portal-modal">
        <DialogOverlay $zIndex={zIndex} data-cy="overlay-modal" />
        <DialogContent
          onInteractOutside={onInteractOutside}
          $fullHeight={fullHeight}
          $biggerModal={biggerModal}
          $sideModal={sideModal}
          $zIndex={zIndex}
          $setWidth={setWidth}
          $height={vhHeight}
          $fullWidth={fullWidth}
          className={className}
          data-cy={dataCy}
          ref={refContent}
          style={style}
        >
          {children}
          <Close asChild>
            {!hiddenCloseButton && (
              <CloseButton
                disabled={loading}
                aria-label="Close"
                data-cy="button-closeModal"
              >
                <CloseIcon />
              </CloseButton>
            )}
          </Close>
        </DialogContent>
      </DialogPortal>
    </Root>
  );
};
