import { type ReactNode } from 'react';

import { useSidebar } from '../../hooks/useSidebar';
import { BaseDropdown } from '../BaseDropdown';
import { Sidebar } from '../Sidebar';
import {
  ArrowDown,
  ButtonContent,
  ButtonProfileDropdown,
  Container,
  Content,
  DropdownButtonProfileContainer,
  DropdownItem,
  HeaderContent,
  HeaderIconContainer,
  IconContainer,
  MainContent,
  MenuIcon,
  Profile,
  ProfileContent,
  ToggleSidebar,
  Toolbar,
  UserIcon,
  UserName,
} from './styles';

export type ISidebarChildren = (isShrunken: boolean) => ReactNode;

export interface ISidebarHeaderProps {
  children: ReactNode;
  headerChildren?: ReactNode;
  logoRedirectTo?: string;
  logoText?: string;
  menuProfileChildren?: ReactNode;
  onSignOut?: () => Promise<void>;
  sidebarChildren?: ISidebarChildren;
  sidebarFooterChildren?: ISidebarChildren;
  userName?: string;
  headerRightChildren?: ReactNode;
}

export const SidebarHeader = ({
  children,
  headerChildren,
  logoRedirectTo,
  logoText,
  menuProfileChildren,
  onSignOut,
  sidebarChildren,
  sidebarFooterChildren,
  userName,
  headerRightChildren,
}: ISidebarHeaderProps): JSX.Element => {
  const { isShrunken, toggleSidebar } = useSidebar();

  const handleToggleIsShrunken = () => {
    toggleSidebar();
  };

  return (
    <Container data-cy="default-layout">
      <Sidebar
        footerChildren={sidebarFooterChildren?.(isShrunken)}
        isShrunken={isShrunken}
        logoRedirectTo={logoRedirectTo}
        logoText={logoText}
        onSignOut={onSignOut}
      >
        {sidebarChildren?.(isShrunken)}
      </Sidebar>
      <Content data-cy="page-with-header-container">
        <Toolbar>
          <HeaderContent data-cy="page-header">
            <ToggleSidebar
              data-cy="button-menu"
              onClick={handleToggleIsShrunken}
              type="button"
            >
              <MenuIcon />
            </ToggleSidebar>

            {headerChildren}
          </HeaderContent>

          <HeaderIconContainer>
            {headerRightChildren}

            <Profile>
              <ProfileContent>
                <DropdownButtonProfileContainer data-cy="profile-actions-container">
                  <BaseDropdown
                    customTrigger={
                      <ButtonProfileDropdown
                        dataCy="dropdown-profileActions"
                        noPaddingRight
                      >
                        <ButtonContent>
                          <IconContainer>
                            <UserIcon />
                          </IconContainer>
                          {userName && (
                            <UserName data-cy={userName}>{userName}</UserName>
                          )}
                          <IconContainer>
                            <ArrowDown />
                          </IconContainer>
                        </ButtonContent>
                      </ButtonProfileDropdown>
                    }
                    disabled={menuProfileChildren === undefined}
                    zIndex={10}
                  >
                    {menuProfileChildren}
                  </BaseDropdown>
                </DropdownButtonProfileContainer>
              </ProfileContent>
            </Profile>
          </HeaderIconContainer>
        </Toolbar>
        <MainContent data-cy="container-pageDefault">{children}</MainContent>
      </Content>
    </Container>
  );
};

export const SidebarHeaderMenuProfileItem = DropdownItem;
