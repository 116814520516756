import { type HTMLAttributes, forwardRef } from 'react';

import { OnlyContent } from './styles';

export interface IDisplayContentProps extends HTMLAttributes<HTMLDivElement> {}

export const DisplayContent = forwardRef<HTMLDivElement, IDisplayContentProps>(
  // eslint-disable-next-line no-restricted-syntax
  function DisplayContent({ ...props }, ref): JSX.Element {
    return <OnlyContent ref={ref} {...props} />;
  }
);
