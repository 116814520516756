import styled from 'styled-components';

import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';
import * as Slider from '@radix-ui/react-slider';

export const SliderRoot = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
`;

export const SliderTrack = styled(Slider.Track)`
  background-color: ${colors.inputPlaceholder};
  position: relative;
  flex-grow: 1;
  border-radius: ${radii.full};
  height: 3px;
`;

export const SliderRange = styled(Slider.Range)`
  position: absolute;
  background-color: ${colors.button};
  border-radius: ${radii.full};
  height: 100%;
`;

export const SliderThumb = styled(Slider.Thumb)`
  display: block;
  width: 20px;
  height: 20px;
  background-color: ${colors.button};
  box-shadow: 0 2px 10px ${colors.button};
  border-radius: ${radii.md};

  &:hover {
    filter: brightness(0.9);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px ${shadows.modal};
  }
`;
