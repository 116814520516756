import { produce } from 'immer';
import { type ReactNode, createContext, useCallback, useState } from 'react';

import * as ToastPrimitive from '@radix-ui/react-toast';

import {
  BaseToast,
  BaseToastViewport,
  type IBaseToastProps,
} from '../components/BaseToast';

export interface IToastContextData {
  addToast: (toast: IBaseToastProps) => void;
}

interface IToastProps {
  children: ReactNode;
}

export const ToastContext = createContext<IToastContextData>(
  {} as IToastContextData
);

export const ToastProvider = ({ children }: IToastProps): JSX.Element => {
  const [toasts, setToasts] = useState<IBaseToastProps[]>([]);

  const addToast = useCallback((toast: IBaseToastProps) => {
    toast.key = new Date().getTime();
    setToasts(
      produce((draft) => {
        draft.push(toast);
      })
    );
  }, []);

  const removeToast = (isOpen: boolean, index: number) => {
    if (isOpen) return;

    setToasts(
      produce((draft) => {
        draft.splice(index, 1);
      })
    );
  };

  return (
    <ToastContext.Provider
      value={{
        addToast,
      }}
    >
      <ToastPrimitive.Provider>
        {children}
        {toasts.map(({ key, ...props }, index) => (
          <BaseToast
            {...props}
            key={key}
            onOpenChange={(isOpen) => {
              removeToast(isOpen, index);
            }}
          />
        ))}
        <BaseToastViewport />
      </ToastPrimitive.Provider>
    </ToastContext.Provider>
  );
};
