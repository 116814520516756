import { useEffect } from 'react';

import { useAuth } from '../../hooks';
import { parseUserUrlSearchParams } from '../../utils';

export const PermissionRedirect = (): JSX.Element | null => {
  const { saveUser } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const { data: UserRedirect, token } = parseUserUrlSearchParams(params);
    UserRedirect.status = UserRedirect.status ?? 'active';

    if (token !== '') {
      localStorage.setItem('JWT_TOKEN', token);
      saveUser(UserRedirect);
    }
  }, []);

  return null;
};
