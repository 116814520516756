import {
  type ButtonHTMLAttributes,
  type ReactElement,
  type ReactNode,
  forwardRef,
} from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { BaseTooltip } from '../BaseTooltip';
import {
  DropdownItem,
  DropdownMenuContent,
  OptionsButton,
  OptionsIcon,
  StyledArrow,
} from './styles';

export interface IBaseDropdownProps
  extends DropdownMenuPrimitive.DropdownMenuProps {
  children: ReactNode;
  customTrigger?: ReactElement<HTMLButtonElement> | null;
  dataCy?: string;
  disabled?: boolean;
  zIndex?: number;
}

export interface IBaseTriggerDropdownProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const BaseTriggerDropdown = forwardRef<
  HTMLButtonElement,
  IBaseTriggerDropdownProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseTriggerDropdown({ ...props }, ref): JSX.Element {
  return (
    <OptionsButton ref={ref} aria-label="More options" {...props}>
      <OptionsIcon />
    </OptionsButton>
  );
});

export const BaseDropdown = ({
  children,
  customTrigger = null,
  dataCy = 'dropdown-menu-button',
  disabled = false,
  zIndex,
  ...props
}: IBaseDropdownProps): JSX.Element => (
  <DropdownMenuPrimitive.Root {...props}>
    <BaseTooltip message="Options">
      <DropdownMenuPrimitive.Trigger
        disabled={disabled}
        asChild
        data-cy={dataCy}
      >
        {customTrigger ?? <BaseTriggerDropdown />}
      </DropdownMenuPrimitive.Trigger>
    </BaseTooltip>

    <DropdownMenuPrimitive.Portal>
      <DropdownMenuContent
        $zIndex={zIndex}
        data-cy="dropdown-menu-content"
        sideOffset={5}
      >
        {children}
        <StyledArrow />
      </DropdownMenuContent>
    </DropdownMenuPrimitive.Portal>
  </DropdownMenuPrimitive.Root>
);

export interface IBaseDropdownItemProps
  extends DropdownMenuPrimitive.DropdownMenuItemProps {
  type?: 'default' | 'large';
}

export const BaseDropdownItem = forwardRef<
  HTMLDivElement,
  IBaseDropdownItemProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseDropdownItem({ type = 'default', ...props }, ref): JSX.Element {
  return <DropdownItem ref={ref} {...props} $type={type} />;
});
