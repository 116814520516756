import { BreadcrumbItem, BreadcrumbLink, Navigation } from './styles';

interface IBreadcrumbsProps {
  links: Array<{ name: string; url: string }> | null;
  onClick: (index: number) => void;
}

export const BreadcrumbsComponent = ({
  links,
  onClick,
}: IBreadcrumbsProps): JSX.Element => {
  const handleClick = (index: number) => {
    onClick(index);
  };

  return (
    <Navigation aria-label="breadcrumb" data-cy="breadcrumb">
      {links &&
        links.length > 0 &&
        links.map((link, index) => {
          const isLast = index === links.length - 1;
          return (
            <BreadcrumbItem
              key={link.name}
              className={`breadcrumb-item ${isLast ? 'active' : ''}`}
              aria-current={isLast ? 'page' : undefined}
              data-cy={`breadcrumb-${index}`}
            >
              {isLast ? (
                link.name
              ) : (
                <BreadcrumbLink
                  to={link.url}
                  onClick={() => {
                    handleClick(index);
                  }}
                >
                  {link.name} {' > '}
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          );
        })}
    </Navigation>
  );
};
