import { type ReactNode } from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';

import { CloseButton, CloseIcon, PopoverArrow, PopoverContent } from './styles';

export interface IBasePopoverProps extends PopoverPrimitive.PopoverProps {
  children: ReactNode;
  dataCy?: string;
  hiddenCloseButton?: boolean;
  trigger: ReactNode;
  zIndex?: number;
}

export const BasePopover = ({
  children,
  dataCy = 'popover-field',
  hiddenCloseButton = false,
  trigger,
  zIndex,
  ...props
}: IBasePopoverProps): JSX.Element => {
  return (
    <PopoverPrimitive.Root {...props}>
      <PopoverPrimitive.Trigger asChild>{trigger}</PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <PopoverContent $zIndex={zIndex} sideOffset={5} data-cy={dataCy}>
          {children}
          <PopoverPrimitive.Close asChild>
            {!hiddenCloseButton && (
              <CloseButton aria-label="Close" data-cy="button-closeModal">
                <CloseIcon />
              </CloseButton>
            )}
          </PopoverPrimitive.Close>
          <PopoverArrow />
        </PopoverContent>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};
