import { type ReactNode } from 'react';

import { Content, Overlay } from './styles';

export interface IFormLayoutProps {
  children: ReactNode;
  dataCy?: string;
}

export const FormLayout = ({
  children,
  dataCy = 'form-container',
}: IFormLayoutProps): JSX.Element => {
  return (
    <>
      <Overlay data-cy="overlay" />
      <Content data-cy={dataCy}>{children}</Content>
    </>
  );
};
