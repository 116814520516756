import { MdClose, MdSearch } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

import { Button } from '../Button';

export const SearchIcon = styled(MdSearch)`
  width: 28px;
  height: 28px;
  color: ${colors.gray200};
  margin-left: 10px;
`;

export const CloseIcon = styled(MdClose)`
  width: 28px;
  height: 28px;
  color: ${colors.gray200};

  &:hover {
    color: ${colors.gray300};
  }
`;

export const SearchButton = styled(Button)`
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const SearchFieldset = styled.fieldset`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  border: 1px solid ${colors.inputBorderLight};
  border-radius: 14px;
  margin-bottom: 1rem;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};
  }
`;

export const InputSearch = styled.input`
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.1;
  outline: none;
  padding: 1rem 1rem 1rem 0.5rem;

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }

  @media (max-width: 337px) {
    width: 100%;
  }
`;

interface ISearchCloseButtonProps {
  $visible?: boolean;
}

export const SearchCloseButton = styled.button<ISearchCloseButtonProps>`
  background-color: transparent;
  border: none;
  margin-right: 12px;

  ${({ $visible = false }) => {
    if (!$visible) {
      return css`
        display: none;
      `;
    } else {
      return css`
        display: flex;
      `;
    }
  }}
`;
