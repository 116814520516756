import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosResponse,
  type CreateAxiosDefaults,
  type InternalAxiosRequestConfig,
} from 'axios';

import { Logger } from '../utils';
import { type IRejectConfig, type IRejectEvent } from './interceptors';

export interface IFrontendUrls {
  edgeRoute: string;
  adminRoute: string;
  clientRoute: string;
}

export interface IResponseDefaultContent {
  success: boolean;
  message: string;
}

interface IApiErrorProps {
  message: string;
  code: number;
}

export interface IErrorDefaultContent {
  error: IApiErrorProps;
  message?: string;
  title?: string;
}

export interface IResponseData<T = { message: string }> {
  data: T;
}

export interface IPaginationPayload {
  page: number;
  limit: number;
  filter: string;
}

export interface IPaginationResponseData<T> extends IResponseData<T> {
  meta: IPaginationMetaProps;
}

export interface IPaginationMetaProps {
  count: number;
  page: number;
  total: number;
  total_pages: number;
}

export type IThrowsError = AxiosError<IResponseDefaultContent>;
export type IApiThrowsError = AxiosError<IErrorDefaultContent>;

interface ICreateApiProps<E = IRejectEvent> extends CreateAxiosDefaults {
  baseURL: string;
  customUrls?: Record<string, string>;
  debug?: boolean;
  events: E;
  handlerRejection?: (
    err: IApiThrowsError,
    config: IRejectConfig<E>
  ) => Promise<AxiosResponse<any, any>>;
  handlerRequest: (
    config: InternalAxiosRequestConfig
  ) => Promise<InternalAxiosRequestConfig<any>>;
}

export const createApi = <E = IRejectEvent>({
  baseURL,
  customUrls,
  debug = false,
  events,
  handlerRejection,
  handlerRequest,
  ...config
}: ICreateApiProps<E>): AxiosInstance => {
  const api = axios.create({
    baseURL,
    withCredentials: true,
    ...config,
  });

  api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      return await handlerRequest(config);
    },
    async (error) => {
      return await Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (res) => {
      debug && Logger.debug('interceptors ok:', res);
      return res;
    },
    async (err: IApiThrowsError) => {
      return await handlerRejection?.(err, {
        axiosInstance: api,
        baseURL,
        customUrls,
        debug,
        events,
      });
    }
  );

  return api;
};
