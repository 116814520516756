import styled, { css } from 'styled-components';

import { colors, fonts, radii } from '@gbs-monorepo-packages/styles';

import { BaseLoading } from '../BaseLoading';
import { BaseSlot } from '../BaseSlot';

interface IOptionsButtonProps {
  noPaddingRight: boolean;
  styleType?: 'accept' | 'admin' | 'decline' | 'default' | 'outline' | 'text';
  textTransform: 'capitalize' | 'lowercase' | 'uppercase';
  fontWeight: number;
}

const buttonStyle = css<IOptionsButtonProps>`
  all: unset;
  align-items: center;
  border-radius: ${radii.md};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 0.875rem;
  justify-content: center;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  font-weight: 700;

  &:hover {
    filter: brightness(0.9);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: unset;
    background-color: ${colors.blue50};
  }

  ${(props) =>
    props.noPaddingRight &&
    css`
      padding-right: 0.25rem;
    `}

  ${(props) =>
    props.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `}

  ${(props) =>
    props.fontWeight &&
    css`
      text-transform: ${props.fontWeight};
    `}

  ${(props) => {
    switch (props.styleType) {
      case 'outline':
        return css`
          background-color: ${colors.white300};
          border: 0.5px solid ${colors.button};
          color: ${colors.button};
          &[disabled] {
            opacity: 0.2;
          }
        `;
      case 'text':
        return css`
          background-color: ${colors.white300};
          color: ${colors.button};
          &[disabled] {
            opacity: 0.25;
          }
        `;
      case 'accept':
        return css`
          background-color: ${colors.statusSuccess};
          color: ${colors.statusTextSuccess};
          &[disabled] {
            opacity: 0.25;
          }
        `;
      case 'decline':
        return css`
          background-color: ${colors.statusError};
          color: ${colors.statusTextError};
          &[disabled] {
            opacity: 0.25;
          }
        `;
      case 'admin':
        return css`
          background-color: ${colors.blue275};
          font-family: ${fonts.admin};
          color: ${colors.white300};
          &[disabled] {
            opacity: 0.25;
          }
        `;
      case 'default':
        return css`
          background-color: ${colors.button};
          color: ${colors.white300};
        `;
      default:
        return css`
          background-color: ${colors.button};
          color: ${colors.white300};
        `;
    }
  }}
`;

export const DefaultButton = styled.button<IOptionsButtonProps>`
  ${buttonStyle};
`;

export const SlotButton = styled(BaseSlot)<IOptionsButtonProps>`
  ${buttonStyle};
`;

export const Loading = styled(BaseLoading)`
  height: 1.3125rem;
  width: 1.3125rem;
`;
