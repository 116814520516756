import { type ReactNode, type RefAttributes, forwardRef } from 'react';

import type * as ToggleGroup from '@radix-ui/react-toggle-group';

import { Count, CountContainer, Item, TabFilterContainer } from './styles';

type IToggleGroup = RefAttributes<HTMLDivElement> &
  (ToggleGroup.ToggleGroupMultipleProps | ToggleGroup.ToggleGroupSingleProps);

export interface ITabProps extends ToggleGroup.ToggleGroupItemProps {
  children: ReactNode;
  dataCy?: string;
  status?: number | string;
}

export const Tab = forwardRef<HTMLButtonElement, ITabProps>(
  // eslint-disable-next-line no-restricted-syntax
  function Tab(
    { children, dataCy = 'tab', status = '', ...props },
    ref
  ): JSX.Element {
    return (
      <Item {...props} data-cy={dataCy} ref={ref}>
        {children}
        {status !== '' && (
          <CountContainer data-cy="tab-button-container">
            <Count data-cy="tab-indicator">{status}</Count>
          </CountContainer>
        )}
      </Item>
    );
  }
);

export type ITabFilterGroupProps = IToggleGroup & {
  children: ReactNode;
  dataCy?: string;
};

export const TabFilterGroup = ({
  children,
  dataCy = 'tab-group',
  ...props
}: ITabFilterGroupProps): JSX.Element => {
  return (
    <TabFilterContainer {...props} data-cy={dataCy}>
      {children}
    </TabFilterContainer>
  );
};
