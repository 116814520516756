import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import { Root as LabelPrimitiveRoot } from '@radix-ui/react-label';

export const baseLabelStyle = css`
  background-color: ${colors.white300};
  color: ${colors.labelText};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
`;

export const Label = styled(LabelPrimitiveRoot)`
  ${baseLabelStyle}
`;

export const LabelSelectData = styled.span`
  ${baseLabelStyle}
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
  readonly isSelectData?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorderLight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};

    & > ${Label}, & > ${LabelSelectData} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  ${({ filled = false, isInvalid = false, isSelectData }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid ${colors.fieldFocus};

          & > ${isSelectData === true ? LabelSelectData : Label} {
            color: ${colors.fieldFocus};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & > ${isSelectData === true ? LabelSelectData : Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}
`;

export const ErrorMessage = styled.p`
  font-size: 0.7rem;
  color: ${colors.red300};
  margin: -5px 0px 15px 0px;
`;

export const ContainerWithError = styled.div`
  flex-grow: 1;
`;
