import { type ReactNode } from 'react';

import { Grid } from './styles';

export interface IFormGridProps {
  children: ReactNode;
}

export const FormGrid = ({ children }: IFormGridProps): JSX.Element => {
  return <Grid data-cy="content-grid">{children}</Grid>;
};
