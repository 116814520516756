import React, { type HTMLAttributes, type ReactNode } from 'react';

import { Content, TabFilterGroupCustom } from './styles';

export interface IPageContentProps extends HTMLAttributes<HTMLDivElement> {
  tabs?: ReactNode;
  defaultTabValue?: string;
  onTabValueChange?: (value: string) => void;
}

export const PageContent = ({
  children,
  tabs,
  defaultTabValue,
  onTabValueChange,
  ...props
}: IPageContentProps): JSX.Element => {
  return (
    <>
      {Boolean(tabs) && (
        <TabFilterGroupCustom
          type="single"
          defaultValue={defaultTabValue}
          onValueChange={onTabValueChange}
        >
          {tabs}
        </TabFilterGroupCustom>
      )}
      <Content {...props}>{children}</Content>
    </>
  );
};
