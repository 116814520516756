import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import { BaseAccordionContent, BaseAccordionTrigger } from '../BaseAccordion';

interface ISidebarItemsProps {
  $isShrunken?: boolean;
}

const sidebarItems = css<ISidebarItemsProps>`
  align-items: center;
  color: ${colors.sidebarTextLight};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isShrunken = false }) =>
    $isShrunken ? 'center' : 'flex-start'};
  padding: 0.5rem 0.3125rem;
  position: relative;
  text-decoration: none;
  width: 100%;
  background-color: ${colors.sidebarBackground};

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    justify-content: ${({ $isShrunken = false }) =>
      !$isShrunken ? 'center' : 'flex-start'};
  }
`;

const activeNavLinkStyled = css`
  color: ${colors.sidebarText};
  background-color: ${colors.sidebarBackgroundActive};

  &::after {
    background-color: ${colors.blue300};
    border-bottom-right-radius: ${radii.md};
    border-top-right-radius: ${radii.md};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 0.25rem;
  }
`;

export const ExpandButton = styled(BaseAccordionTrigger)<ISidebarItemsProps>`
  ${sidebarItems}
  border: none;
  padding: 1rem
    ${({ $isShrunken = false }) => ($isShrunken ? '0.75rem' : '1.875rem')};

  :hover {
    filter: brightness(0.9);
  }

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    padding: 1rem
      ${({ $isShrunken = false }) => (!$isShrunken ? '0.75rem' : '1.875rem')};
  }
`;

export const NavLinkCustom = styled(NavLink)`
  text-decoration: none;

  &.active ${ExpandButton} {
    ${activeNavLinkStyled}
  }
`;

// prettier-ignore
export const ExpandButtonContent = styled(BaseAccordionContent)<ISidebarItemsProps>`
  ${({ $isShrunken = false }) =>
    $isShrunken &&
    css`
      display: none;
    `};
`;

export const ButtonItem = styled.button<ISidebarItemsProps>`
  ${sidebarItems}
  border: none;
  padding: 1rem
    ${({ $isShrunken = false }) => ($isShrunken ? '0.75rem' : '1.875rem')};

  :hover {
    filter: brightness(0.9);
  }

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    padding: 1rem
      ${({ $isShrunken = false }) => (!$isShrunken ? '0.75rem' : '1.875rem')};
  }
`;

export const LinkItem = styled(NavLink)<ISidebarItemsProps>`
  ${sidebarItems}
  color: ${colors.sidebarTextLight};
  padding: 0.875rem
    ${({ $isShrunken = false }) => ($isShrunken ? '0.75rem' : '1.875rem')};

  :hover {
    filter: brightness(0.9);
  }

  &.active {
    ${activeNavLinkStyled}
  }

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    padding: 0.875rem
      ${({ $isShrunken = false }) => (!$isShrunken ? '0.75rem' : '1.875rem')};
  }
`;

interface ITextProps {
  $isShrunken?: boolean;
}

export const Text = styled.p<ITextProps>`
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 1rem;
  text-align: left;

  ${({ $isShrunken = false }) =>
    $isShrunken &&
    css`
      display: none;
    `}
  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    display: block;

    ${({ $isShrunken = false }) =>
      !$isShrunken &&
      css`
        display: none;
      `}
  }
`;
