export enum ETypeFolder {
  PUBLIC = 1,
  PRIVATE = 2,
  CLIENT = 3,
  RENEWAL = 4,
  EDGE = 5,
}

export const documentStatus = {
  approved: 'Approved',
  declined: 'Declined',
  waitingForApproval: 'Waiting For Approval',
};
