import { css } from 'styled-components';

export const underlineAnimation = css`
  position: relative;

  &::after {
    content: '';
    background-color: currentColor;
    bottom: 0;
    height: 1px;
    left: 0;
    position: absolute;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.25s ease-out;
    width: 100%;
  }

  &:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
  }
`;
