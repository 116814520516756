import { MdKeyboardArrowDown } from 'react-icons/md';
import styled from 'styled-components';

import { Trigger } from '@radix-ui/react-accordion';

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
  transition: transform 300ms;
`;

export const AccordionTrigger = styled(Trigger)`
  &[data-state='open'] {
    & ${ArrowDown} {
      transform: rotate(180deg);
    }
  }
`;
