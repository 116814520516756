import styled, { css } from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

interface IContainerProps {
  center?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  width: 100%;

  ${({ center = false }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

export const IconContainer = styled.div`
  align-items: center;
  color: ${colors.textLight};
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  width: 4rem;
`;
