import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

export const TabFilterContainer = styled(ToggleGroup.Root)`
  border-bottom: 1px solid ${colors.divider};
  display: flex;
`;

export const CountContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.375rem;
`;

export const Count = styled.span`
  align-items: center;
  background-color: ${colors.lightGray100};
  border-radius: ${radii.px};
  color: ${colors.textLight};
  display: flex;
  font-size: 0.625rem;
  justify-content: center;
  padding: 0.0625rem 0.25rem;
`;

export const Item = styled(ToggleGroup.Item)`
  all: unset;
  align-items: center;
  background-color: ${colors.backgroundDarker};
  border-top-left-radius: ${radii.sm};
  border-top-right-radius: ${radii.sm};
  cursor: pointer;
  display: flex;
  padding: 0.5rem 0.375rem;

  &[data-disabled] {
    color: ${colors.gray200};
    pointer-events: none;
    & ${Count} {
      background-color: ${colors.lightGray100};
    }
  }

  &[data-state='on'] {
    position: relative;
    &::after {
      content: '';
      background-color: currentColor;
      border-top-left-radius: ${radii.px};
      border-top-right-radius: ${radii.px};
      bottom: 0;
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &[data-state='off'] {
    color: ${colors.textLight};
    //   & {Indicator} {
    //     display: none;
    //   }
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
