import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

import { BaseSlot } from '../BaseSlot';

export const Container = styled.div`
  background: ${colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  & > button {
    height: 3.25rem;
  }
`;

export const SelectViewportSlot = styled(BaseSlot)`
  padding: 0.315rem;
`;
