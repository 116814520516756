import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import { TabFilterGroup } from '../TabFilterGroup';

const spaceTop = 1.25;
const spaceBetween = 1.75;

export const Content = styled.div`
  margin-top: ${spaceTop + spaceBetween}rem;
  background-color: ${colors.white300};
  border-radius: ${radii.xl};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;

export const TabFilterGroupCustom = styled(TabFilterGroup)`
  margin-top: ${spaceTop}rem;
  & + ${Content} {
    margin-top: ${spaceBetween}rem;
  }
`;
