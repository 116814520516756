import { MdCheck } from 'react-icons/md';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import { FileIcon } from '../FileIcon';

export const FileDropzoneContainer = styled.div`
  margin-bottom: 20px;
`;

export const FilesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const FileContainer = styled.li`
  align-items: center;
  background-color: ${colors.backgroundHover};
  border-radius: ${radii.lg};
  color: ${colors.text};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  transition: border 0.24s ease-in-out;

  & > span {
    display: contents;
  }
`;

export const FileIconCustom = styled(FileIcon)`
  width: 40px;
  height: 40px;
`;

export const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 1.25rem;
`;

export const FileName = styled.p`
  font-size: 0.75rem;
`;

export const FileSize = styled.p`
  font-size: 0.75rem;
  color: ${colors.textLight};
`;

export const FileAcceptedIcon = styled(MdCheck)`
  color: ${colors.text};
  width: 1.25rem;
  height: 1.25rem;
`;
