import type * as Slider from '@radix-ui/react-slider';

import { SliderRange, SliderRoot, SliderThumb, SliderTrack } from './styles';

export interface IBaseSliderProps extends Slider.SliderProps {
  dataCy?: string;
}

export const BaseSlider = ({
  dataCy = 'slider-field',
  ...props
}: IBaseSliderProps): JSX.Element => {
  return (
    <SliderRoot data-cy={dataCy} {...props}>
      <SliderTrack>
        <SliderRange />
      </SliderTrack>
      <SliderThumb />
    </SliderRoot>
  );
};
