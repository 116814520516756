import { type Property } from 'csstype';
import { type AnchorHTMLAttributes } from 'react';

import { Subtitle, TableCellContainer, Text, TextLink } from './styles';

export type ITableCellProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'children'
> & {
  dataCy?: string;
  subtitle?: string;
  href?: string;
  text: string;
  textAlign?: Property.TextAlign;
};

export const TableCell = ({
  className,
  style,
  subtitle,
  text,
  textAlign = 'center',
  ...anchorProps
}: ITableCellProps): JSX.Element => {
  return (
    <TableCellContainer
      className={className}
      style={style}
      textAlign={textAlign}
      data-cy={text}
    >
      {anchorProps.href ? (
        <TextLink {...anchorProps} title={text} target="_blank" data-cy={text}>
          {text}
        </TextLink>
      ) : (
        <Text title={text} data-cy={`title-${text}`}>
          {text}
        </Text>
      )}

      {subtitle && (
        <Subtitle title={subtitle} data-cy={`sub-title-${subtitle}`}>
          {subtitle}
        </Subtitle>
      )}
    </TableCellContainer>
  );
};
