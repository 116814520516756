import { createGlobalStyle } from 'styled-components';

import { colors } from './constants/colors';

export * from './constants/animations';
export * from './constants/colors';
export * from './constants/fonts';
export * from './constants/radii';
export * from './constants/scrollbar';
export * from './constants/shadows';

export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${colors.white300};
  }

  body,
  input,
  textarea,
  button {
    color: ${colors.text};
    font-family: 'Montserrat', 'Roboto', sans-serif;
    font-weight: 400;
  }

  button {
    cursor: pointer;
  }

  html, body, #root {
    height: 100%;
  }

  [disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
