import { forwardRef } from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import { AccordionTrigger, ArrowDown } from './styles';

export type IBaseAccordionProps =
  | Accordion.AccordionMultipleProps
  | Accordion.AccordionSingleProps;

export const BaseAccordion = forwardRef<HTMLDivElement, IBaseAccordionProps>(
  // eslint-disable-next-line no-restricted-syntax
  function BaseAccordion({ ...props }, ref): JSX.Element {
    return <Accordion.Root ref={ref} {...props} />;
  }
);

export interface IBaseAccordionItemProps extends Accordion.AccordionItemProps {}

export const BaseAccordionItem = forwardRef<
  HTMLDivElement,
  IBaseAccordionItemProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseAccordionItem({ ...props }, ref) {
  return <Accordion.Item {...props} ref={ref} />;
});

export interface IBaseAccordionTriggerProps
  extends Accordion.AccordionTriggerProps {
  noArrow?: boolean;
}

export const BaseAccordionTrigger = forwardRef<
  HTMLButtonElement,
  IBaseAccordionTriggerProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseAccordionTrigger({ children, noArrow = false, ...props }, ref) {
  return (
    <Accordion.Header className="AccordionHeader">
      <AccordionTrigger {...props} ref={ref}>
        {children}
        {!noArrow && <ArrowDown aria-hidden />}
      </AccordionTrigger>
    </Accordion.Header>
  );
});

export interface IBaseAccordionContentProps
  extends Accordion.AccordionContentProps {}

export const BaseAccordionContent = forwardRef<
  HTMLDivElement,
  IBaseAccordionContentProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseAccordionContent({ ...props }, ref) {
  return <Accordion.Content {...props} ref={ref} />;
});
