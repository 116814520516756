import { format } from 'date-fns';

import { SYSTEM_DATE_PATTERN } from '../constants';

export const dateFormatFromJSON = (
  date: Date | string,
  pattern = SYSTEM_DATE_PATTERN,
  timeZone = 'UTC'
): string => {
  if (typeof date === 'object') {
    date = changeTimeZone(date, timeZone);
  }

  return format(date, pattern);
};

const changeTimeZone = (date: Date | string, timeZone: string) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    })
  );
};

export const formatKebabCase = (
  str: string,
  separator: RegExp | string = ' '
): string => str.split(separator).join('-').toLowerCase();

export const formatPhoneNumber = (phone: string): string => {
  const cleaned = phone.trim().replace(/\D/g, '');
  const match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{4})$/);
  const match2 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  const match3 = cleaned.match(/^(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  if (match2) {
    return '(' + match2[1] + ') ' + match2[2] + '-' + match2[3];
  }
  if (match3) {
    return match3[1] + '-' + match3[2];
  }
  return phone;
};

type IQueryValue = boolean | number | string | null;
export const queryStringFormat = (
  params: Record<string, IQueryValue>
): string => {
  const search = new URLSearchParams();
  for (const key in params) {
    const value = params[key];
    if (value === null) continue;
    search.append(key, value.toString());
  }
  return search.toString();
};

enum SizeUnit {
  K = 1000,
  M = 1000 ** 2,
  G = 1000 ** 3,
}

export const formatSize = (size: number, sizeUnit = 'B'): string => {
  switch (true) {
    case size < SizeUnit.K:
      return `${size.toFixed(0)} ${sizeUnit}`;
    case size < SizeUnit.M:
      return `${(size / SizeUnit.K).toFixed(0)} K${sizeUnit}`;
    case size < SizeUnit.G:
      return `${(size / SizeUnit.M).toFixed(0)} M${sizeUnit}`;
    default:
      return `${(size / SizeUnit.G).toFixed(0)} G${sizeUnit}`;
  }
};
