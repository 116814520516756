import { MdOutlineReply } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

const BACKGROUND_COLORS = {
  me: colors.messageBackgroundMe,
  admin: colors.messageBackgroundClient,
};

interface IContainerProps {
  sender: 'admin' | 'me';
  hideName?: boolean;
  hasAvatar?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  display: flex;
  flex-direction: ${({ sender }) => (sender !== 'me' ? 'row' : 'row-reverse')};
  margin-top: ${({ hideName }) => (hideName === true ? '0.875rem' : '1.5rem')};
`;

export const ContainerMessage = styled.div`
  max-width: 70%;
  margin-right: 0.5rem;
`;

export const MessageDetails = styled.div<IContainerProps>`
  display: flex;
  justify-content: ${({ sender }) =>
    sender !== 'me' ? 'flex-start' : 'flex-end'};
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  margin-right: 0.7rem;
`;

export const MessageDate = styled.div`
  color: ${colors.gray500};
  font-size: 0.75rem;
`;

export const MessageDescription = styled.div<IContainerProps>`
  flex: 1;
  border-radius: ${radii.sm};
  padding: 0.75rem;
  white-space: pre-line;
  overflow-wrap: anywhere;
  width: 100%;

  ${({ sender }) => css`
    background-color: ${BACKGROUND_COLORS[sender]};
  `}

  ${({ hasAvatar, sender }) =>
    hasAvatar &&
    css`
      ${sender === 'me' ? 'margin-right: 30px;' : 'margin-left: 30px;'}
    `}
`;

export const MessageBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

const IconStyle = css`
  width: 24px;
  height: 24px;
  color: ${colors.gray300};

  &:hover {
    color: ${colors.blue300};
  }
`;

export const IconReply = styled(MdOutlineReply)<IContainerProps>`
  ${IconStyle}
  order: 0;
  ${({ sender }) =>
    sender !== 'me' &&
    css`
      transform: scaleX(-1);
      order: 1;
    `}
`;
export const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  border: 1px solid ${colors.gray100};
`;

export const Avatar = styled(Circle)<IContainerProps>`
  position: absolute;
  bottom: 10px;
  order: 0;
  margin-right: 0.5rem;
  ${({ sender }) =>
    sender !== 'me' &&
    css`
      order: 1;
    `}
`;

export const ImgAvatar = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  object-fit: contain;
`;
