import { BiDownArrow, BiUpArrow } from 'react-icons/bi';
import styled, { css } from 'styled-components';

import { colors, fonts, scrollbar } from '@gbs-monorepo-packages/styles';

import { BaseLoading } from '../BaseLoading';
import { DisplayContent } from '../DisplayContent';

export const HeaderItemGrid = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  padding: 0.5rem;
  padding-bottom: 0.75rem;
  position: sticky;
  top: 0;
  background-color: ${colors.white300};
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface IMainContentProps {
  $columns: number;
  $themeStyle?: 'admin' | 'default';
}

interface ITableRowProps {
  $cursor: string;
}

const childAsHeaderStyle = css<IMainContentProps>`
  & > div {
    border-bottom: 1px solid ${colors.divider};
    ${({ $columns }) => css`
      &:hover:not(:nth-child(${$columns})) {
        filter: brightness(0.96);
        /* cursor: pointer; */
      }
    `};
  }
`;

const fontStyle = css<IMainContentProps>`
  ${({ $themeStyle = 'default' }) =>
    $themeStyle === 'admin' &&
    css`
      font-family: ${fonts.admin};
    `}
`;

export const MainContent = styled.div<IMainContentProps>`
  color: ${colors.textLight};
  display: grid;
  grid-template-columns: ${({ $columns }) =>
    `minmax(min(50px, 100%), 2fr) repeat(${
      $columns > 2 ? $columns - 2 : 0
    }, minmax(min(50px, 100%), 0.75fr)) auto`};
  width: 100%;

  ${scrollbar}

  ${childAsHeaderStyle};
  ${fontStyle};
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  margin-top: 1.5rem;
  width: 3.25rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const childAsRowStyle = css`
  & > div:not(${HeaderItemGrid}) {
    border-bottom: 1px solid ${colors.divider};
    width: 100%;
  }

  &:hover > div:not(${HeaderItemGrid}) {
    background-color: ${colors.backgroundHover};
  }
`;

export const GroupContent = styled(DisplayContent)<ITableRowProps>`
  cursor: ${({ $cursor }) => $cursor};
  ${childAsRowStyle};
`;

export const SortedDownIcon = styled(BiDownArrow)`
  color: ${colors.textLight};
`;

export const SortedUpIcon = styled(BiUpArrow)`
  color: ${colors.textLight};
`;
