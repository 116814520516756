import styled from 'styled-components';

import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';

export const Overlay = styled.div`
  background-color: ${colors.authPagesBackground};
  inset: 0;
  position: fixed;
`;

export const Content = styled.div`
  background-color: ${colors.white300};
  border-radius: ${radii.lg};
  box-shadow: ${shadows.modal};
  left: 50%;
  max-width: 450px;
  padding: 25px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;

  &:focus {
    outline: none;
  }
`;
