import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, fonts } from '@gbs-monorepo-packages/styles';

export const Navigation = styled.nav`
  margin: 1rem 0;
`;
export const BreadcrumbItem = styled.span`
  font-family: ${fonts.admin};
  color: ${colors.textLight};
  &.active {
    color: ${colors.gray300};
  }
`;
export const BreadcrumbLink = styled(Link)`
  color: ${colors.textLight};
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
