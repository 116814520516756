import { HiOutlineMenuAlt2, HiOutlineOfficeBuilding } from 'react-icons/hi';
import {
  MdGridView,
  MdLogout,
  MdOutlineFolder,
  MdOutlineSettings,
} from 'react-icons/md';
import { RiBookLine } from 'react-icons/ri';
import { SiBookstack } from 'react-icons/si';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

import { BaseAccordion } from '../BaseAccordion';

interface IOverlayToggleToMobileProps {
  $isShrunken?: boolean;
}

export const OverlayToggleToMobile = styled.div<IOverlayToggleToMobileProps>`
  background-color: ${colors.blackTranslucent400};
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */

    ${({ $isShrunken = false }) =>
      $isShrunken &&
      css`
        display: block;
      `}
  }
`;

interface IContainerProps {
  $isShrunken?: boolean;
}

export const Container = styled.nav<IContainerProps>`
  background: ${colors.sidebarBackground};
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  padding-top: 0.3125rem;
  border-right: 1px solid ${colors.divider};
  width: ${({ $isShrunken = false }) => ($isShrunken ? '4.5rem' : '270px')};
  min-width: ${({ $isShrunken = false }) => ($isShrunken ? '4.5rem' : '270px')};

  overflow-y: auto;
  ${scrollbar}

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    width: ${({ $isShrunken = false }) => (!$isShrunken ? '4.5rem' : '270px')};
    min-width: ${({ $isShrunken = false }) =>
      !$isShrunken ? '4.5rem' : '270px'};

    ${({ $isShrunken = false }) =>
      $isShrunken &&
      css`
        position: absolute;
        z-index: 2;
      `}
  }
`;

interface ISidebarItemsProps {
  $isShrunken?: boolean;
}

const sidebarItems = css<ISidebarItemsProps>`
  align-items: center;
  color: ${colors.sidebarTextLight};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isShrunken = false }) =>
    $isShrunken ? 'center' : 'flex-start'};
  padding: 0.5rem 0.3125rem;
  position: relative;
  text-decoration: none;
  width: 100%;
  background-color: ${colors.sidebarBackground};

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    justify-content: ${({ $isShrunken = false }) =>
      !$isShrunken ? 'center' : 'flex-start'};
  }
`;

export const NavLogoContainer = styled(NavLink)<ISidebarItemsProps>`
  ${sidebarItems}
  padding: 0.5rem
    ${({ $isShrunken = false }) => ($isShrunken ? '0.3125rem' : '1.25rem')};

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    padding: 0.5rem
      ${({ $isShrunken = false }) => (!$isShrunken ? '0.3125rem' : '1.25rem')};
  }
`;

export const Logo = styled.img`
  width: 3rem;
`;

interface ILogoTextProps {
  $isShrunken?: boolean;
}

export const LogoText = styled.h2<ILogoTextProps>`
  color: ${colors.sidebarText};
  font-size: 1.125rem;
  font-weight: 700;
  margin-left: 0.5rem;

  ${({ $isShrunken = false }) =>
    $isShrunken &&
    css`
      display: none;
    `}

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    display: block;

    ${({ $isShrunken = false }) =>
      !$isShrunken &&
      css`
        display: none;
      `}
  }
`;

interface IToggleSidebarContainerProps {
  $isShrunken?: boolean;
}

export const ToggleSidebarContainerToMobile = styled.div<IToggleSidebarContainerProps>`
  align-items: center;
  display: none;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */

    ${({ $isShrunken = false }) =>
      $isShrunken &&
      css`
        display: flex;
      `}
  }
`;

export const ToggleSidebar = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.headerText};
  cursor: pointer;
  display: none;
  font-family: inherit;
  height: 1.875rem;
  justify-content: center;
  width: 1.875rem;

  &:hover {
    background-color: ${colors.white300};
    filter: brightness(0.9);
  }

  @media screen and (max-width: 600px) {
    display: flex;
  }
`;

export const MenuIcon = styled(HiOutlineMenuAlt2)`
  height: 1.3125rem;
  width: 1.3125rem;
`;

export const SpaceAccordion = styled(BaseAccordion)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

export const SpaceToEnd = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  width: 100%;
`;

export const NavItemContainer = styled(NavLink)<ISidebarItemsProps>`
  ${sidebarItems}
  color: ${colors.sidebarTextLight};
  padding: 0.875rem
    ${({ $isShrunken = false }) => ($isShrunken ? '0.75rem' : '1.875rem')};

  :hover {
    filter: brightness(0.9);
  }

  &.active {
    color: ${colors.sidebarText};
    background-color: ${colors.sidebarBackgroundActive};

    &::before {
      background-color: ${colors.blue300};
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 0.25rem;
    }
  }

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    padding: 0.875rem
      ${({ $isShrunken = false }) => (!$isShrunken ? '0.75rem' : '1.875rem')};
  }
`;

export const DashBoardIcon = styled(MdGridView)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const MembersIcon = styled(RiBookLine)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const CompaniesIcon = styled(HiOutlineOfficeBuilding)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const CoursesIcon = styled(SiBookstack)`
  height: 1.5rem;
  width: 1.5rem;
  fill: transparent;
  stroke: currentColor;
  stroke-width: 1px;
`;

export const DocumentsIcon = styled(MdOutlineFolder)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const SettingsIcon = styled(MdOutlineSettings)`
  height: 1.5rem;
  width: 1.5rem;
`;

interface ITextProps {
  $isShrunken?: boolean;
}

export const Text = styled.p<ITextProps>`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 1rem;

  ${({ $isShrunken = false }) =>
    $isShrunken &&
    css`
      display: none;
    `}
  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    display: block;

    ${({ $isShrunken = false }) =>
      !$isShrunken &&
      css`
        display: none;
      `}
  }
`;

export const LogoutButton = styled.button<ISidebarItemsProps>`
  ${sidebarItems}
  border: none;
  padding: 1rem
    ${({ $isShrunken = false }) => ($isShrunken ? '0.75rem' : '1.875rem')};
  position: relative;

  :hover {
    filter: brightness(0.9);
  }

  @media screen and (max-width: 600px) {
    /* is a reverse logic of isShrunken */
    padding: 1rem
      ${({ $isShrunken = false }) => (!$isShrunken ? '0.75rem' : '1.875rem')};
  }
`;

export const LogoutIcon = styled(MdLogout)`
  height: 1.5rem;
  width: 1.5rem;
`;
