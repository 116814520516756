import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.white300};
`;
