import { type HTMLAttributes, forwardRef } from 'react';

import { Text } from './styles';

export type ITextStatusType =
  | 'accepted'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

export interface ITextStatusProps extends HTMLAttributes<HTMLParagraphElement> {
  dataCy?: string;
  type?: ITextStatusType;
}

export const TextStatus = forwardRef<HTMLParagraphElement, ITextStatusProps>(
  // eslint-disable-next-line no-restricted-syntax
  function TextStatus(
    { dataCy = 'text-status', type = 'info', ...props },
    ref
  ): JSX.Element {
    return <Text {...props} ref={ref} $type={type} data-cy={dataCy} />;
  }
);
