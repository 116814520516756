import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import * as SwitchPrimitive from '@radix-ui/react-switch';

export const SwitchRoot = styled(SwitchPrimitive.Root)`
  width: 34px;
  height: 18px;
  background-color: ${colors.gray300};
  border-radius: ${radii.full};
  position: relative;
  box-shadow: 0 2px 10px ${colors.gray100};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    box-shadow: 0 0 0 2px ${colors.gray100};
  }

  &[data-state='checked'] {
    background-color: ${colors.green300};
  }
`;

export const SwitchThumb = styled(SwitchPrimitive.Thumb)`
  display: block;
  width: 12px;
  height: 12px;
  background-color: ${colors.white300};
  border-radius: ${radii.full};
  box-shadow: 0 2px 2px ${colors.gray100};
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(16px);
  }
`;
