import { format, parse } from 'date-fns';
import {
  type InputHTMLAttributes,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { Input } from './styles';

type InputType = 'number' | 'password' | 'text';

export interface IFormInputTextProps
  extends InputHTMLAttributes<HTMLInputElement> {
  dataCy?: string;
  type: InputType;
  name: string;
  autoComplete?: string;
}

export const FormInputText = forwardRef<HTMLInputElement, IFormInputTextProps>(
  // eslint-disable-next-line no-restricted-syntax
  function FormInputText(
    {
      dataCy,
      type: inputType = 'text',
      name,
      autoComplete = 'off',
      ...inputProps
    },
    ref
  ): JSX.Element {
    const { register } = useFormContext();

    return (
      <Input
        // @ts-expect-error: 'ref' is specified more than once, so this usage will be overwritten.
        ref={ref}
        {...inputProps}
        {...register(name, {
          valueAsNumber: inputType === 'number',
        })}
        autoComplete={autoComplete}
        type={inputType}
        data-cy={dataCy}
      />
    );
  }
);

export interface IFormInputDateProps
  extends InputHTMLAttributes<HTMLInputElement> {
  dataCy?: string;
  name: string;
  id: string;
  value?: string;
  onValueChange?: (value: string) => void;
}

export const FormInputDate = forwardRef<HTMLInputElement, IFormInputDateProps>(
  // eslint-disable-next-line no-restricted-syntax
  function FormInputDate(
    { dataCy, name, id, value, onValueChange, ...inputProps },
    ref
  ): JSX.Element {
    const { register } = useFormContext();
    const [valueDate, setValueDate] = useState(value ?? '');

    const isValidDate = (date: string): boolean => {
      const datePattern = /^2\d{3}-\d{2}-\d{2}$/;

      if (!datePattern.test(date)) return false;

      const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
      return parsedDate.toString() !== 'Invalid Date';
    };

    const handleValueDate = (date: string) => {
      setValueDate(date);
      onValueChange?.(date);
    };

    const handleBlurInputValueDate = (date: string) => {
      if (!isValidDate(date)) {
        handleValueDate('');
      }
    };

    const handleShowPicker = useCallback((htmlId: string) => {
      const element = document.getElementById(htmlId) as HTMLInputElement;
      if ('showPicker' in element) {
        element.showPicker();
      }
    }, []);

    return (
      <Input
        {...inputProps}
        {...register(name)}
        ref={ref}
        value={valueDate}
        id={id}
        name={name}
        onChange={(e) => {
          handleValueDate(e.target.value);
        }}
        type="date"
        min={format(new Date(), 'yyyy-MM-dd')}
        onClick={() => {
          handleShowPicker(id);
        }}
        onBlur={(e) => {
          handleBlurInputValueDate(e.target.value);
        }}
        data-cy={dataCy}
      />
    );
  }
);
