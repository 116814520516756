import { Navigate, Outlet, useLocation } from 'react-router-dom';

import {
  type IRoutePath,
  Logger,
  getRouteFrom,
} from '@gbs-monorepo-packages/common';

import { type IUseCanParams, useCan } from '../../hooks/useCan';

export type IPrivateRouteProps = IUseCanParams & {
  children?: JSX.Element;
  loginPath: IRoutePath;
  redirectPath: IRoutePath;
};

export const PrivateRoute = ({
  children,
  loginPath,
  permissions,
  redirectPath,
  roles,
}: IPrivateRouteProps): JSX.Element => {
  const { pathname } = useLocation();
  const userCanSee = useCan({ permissions, roles });
  if (userCanSee ?? false) {
    return children ?? <Outlet />;
  }

  const fullPath = getRouteFrom(userCanSee === null ? loginPath : redirectPath);
  Logger.debug('redirecting you to ', fullPath);
  return <Navigate to={fullPath} replace state={{ from: pathname }} />;
};
