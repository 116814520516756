import { forwardRef } from 'react';

import * as Tabs from '@radix-ui/react-tabs';

export type IBaseTabsProps = Tabs.TabsProps;

export const BaseTabs = forwardRef<HTMLDivElement, IBaseTabsProps>(
  // eslint-disable-next-line no-restricted-syntax
  function BaseTabs({ ...props }, ref): JSX.Element {
    return <Tabs.Root ref={ref} {...props} />;
  }
);

export interface IBaseTabsListProps extends Tabs.TabsListProps {}

export const BaseTabsList = forwardRef<HTMLDivElement, IBaseTabsListProps>(
  // eslint-disable-next-line no-restricted-syntax
  function BaseTabsList({ ...props }, ref) {
    return <Tabs.List {...props} ref={ref} />;
  }
);

export interface IBaseTabsTriggerProps extends Tabs.TabsTriggerProps {}

export const BaseTabsTrigger = forwardRef<
  HTMLButtonElement,
  IBaseTabsTriggerProps
>(
  // eslint-disable-next-line no-restricted-syntax
  function BaseTabsTrigger({ children, ...props }, ref) {
    return (
      <Tabs.Trigger {...props} ref={ref}>
        {children}
      </Tabs.Trigger>
    );
  }
);

export interface IBaseTabsContentProps extends Tabs.TabsContentProps {}

export const BaseTabsContent = forwardRef<
  HTMLDivElement,
  IBaseTabsContentProps
>(
  // eslint-disable-next-line no-restricted-syntax
  function BaseTabsContent({ ...props }, ref) {
    return <Tabs.Content {...props} ref={ref} />;
  }
);
