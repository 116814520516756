import { type AxiosInstance } from 'axios';

import { Logger } from '@gbs-monorepo-packages/common';

import {
  authBroadcastChannelEvents,
  authBroadcastChannelName,
} from '../constants';
import { AuthService, type IAuthUrls } from './auth';

const authChannel = new BroadcastChannel(authBroadcastChannelName);

export const broadcastSignOut = (): void => {
  authChannel.postMessage(authBroadcastChannelEvents.SignOut);
};

export const notifyForbidden = (): void => {
  authChannel.postMessage(authBroadcastChannelEvents.NotifyForbidden);
};

export const refreshToken = async (
  axiosInstance: AxiosInstance,
  customUrls?: Partial<IAuthUrls>
): Promise<void> => {
  const authServices = new AuthService(axiosInstance, customUrls);
  Logger.debug('REFRESH TOKEN');
  await authServices.refresh();
};
