import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

export const Input = styled.input`
  -webkit-appearance: none;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  width: 100%;
  &::placeholder {
    color: ${colors.inputPlaceholder};
  }
  @media (max-width: 337px) {
    width: 100%;
  }
`;
