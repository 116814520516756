import styled from 'styled-components';

import { BaseLoading } from '../BaseLoading';

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  margin-top: 1.5rem;
  width: 3.25rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;
