import { type ReactNode } from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { TooltipArrow, TooltipContent } from './styles';

export interface IBaseTooltipProps
  extends TooltipPrimitive.TooltipProviderProps {
  children: ReactNode;
  message?: string;
  dataCy?: string;
}

export const BaseTooltip = ({
  children,
  message,
  dataCy = 'tooltip-field',
  ...props
}: IBaseTooltipProps): JSX.Element => {
  return (
    <TooltipPrimitive.Provider {...props}>
      <TooltipPrimitive.Root delayDuration={300}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipContent sideOffset={5} data-cy={dataCy}>
            {message}
            <TooltipArrow />
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
