import {
  type AxiosError,
  type AxiosInstance,
  type AxiosResponse,
  HttpStatusCode,
  type InternalAxiosRequestConfig,
} from 'axios';

import {
  type IApiThrowsError,
  type IRejectConfig,
  type IRejectEvent,
  Logger,
} from '@gbs-monorepo-packages/common';

import { type IAuthUrls } from './auth';

interface IFailedRequests {
  onSuccess: () => void;
  onFailure: (err: AxiosError) => void;
}

let isRefreshing = false;
const failedRequestsQueue = new Set<Partial<IFailedRequests>>();

export interface IAuthRejectEvent extends IRejectEvent {
  handlerBroadcastSignOut: () => void;
  handlerNotifyForbidden: () => void;
  handlerRefresh: (
    axiosInstance: AxiosInstance,
    customUrls?: Partial<IAuthUrls>
  ) => Promise<void>;
}

export interface IAuthRejectConfig extends IRejectConfig<IAuthRejectEvent> {}

export const handlerRequest = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig<any>> => {
  config.headers.Authorization = `Bearer ${
    localStorage.getItem('JWT_TOKEN') ?? ''
  }`;

  return await Promise.resolve(config);
};

export const handlerRejection = async (
  err: IApiThrowsError,
  {
    axiosInstance,
    customUrls,
    baseURL,
    debug = false,
    events,
  }: IAuthRejectConfig
): Promise<AxiosResponse<any, any>> => {
  debug && Logger.debug('interceptors ERROR:', [err, err.response?.status]);
  if (err.config?.baseURL === baseURL) {
    switch (err.response?.status) {
      case HttpStatusCode.Unauthorized:
        if (
          err.response.data?.message === 'Expired JWT Token' ||
          err.response.data?.error?.message === 'Expired JWT Token'
        ) {
          return await tryRefreshingToken(
            err,
            axiosInstance,
            events,
            customUrls
          );
        } else {
          events.handlerBroadcastSignOut();
        }
        break;
      case HttpStatusCode.Forbidden:
        if (
          err.response.data?.message === 'Forbidden' ||
          err.response.data?.error?.message === 'Forbidden'
        ) {
          events.handlerNotifyForbidden();
        } else if (
          err.response.data?.message?.includes('Access Denied') ??
          err.response.data?.error?.message.includes('Access Denied')
        ) {
          return await tryRefreshingToken(
            err,
            axiosInstance,
            events,
            customUrls
          );
        }
        break;
    }
  }

  return await Promise.reject(err);
};

const tryRefreshingToken = async (
  err: IApiThrowsError,
  axiosInstance: AxiosInstance,
  events: IAuthRejectEvent,
  customUrls: Record<string, string> | undefined
) => {
  const originalConfig = err.config ?? {};

  const failedRequest: Partial<IFailedRequests> = {};
  // enqueue failed requests
  const promiseFailedRequest = new Promise<AxiosResponse>((resolve, reject) => {
    failedRequest.onSuccess = () => {
      resolve(axiosInstance(originalConfig));
    };
    failedRequest.onFailure = (err: AxiosError) => {
      reject(err);
    };
    failedRequestsQueue.add(failedRequest);
  });

  if (!isRefreshing) {
    isRefreshing = true;
    // refresh token
    try {
      await events.handlerRefresh(axiosInstance, customUrls);
      // make failed requests again
      failedRequestsQueue.forEach((req) => {
        req.onSuccess?.();
      });
    } catch (error) {
      // set requests with failure
      failedRequestsQueue.forEach((req) => {
        req.onFailure?.(err);
      });

      // logout user in all browser tabs
      events.handlerBroadcastSignOut();
    } finally {
      isRefreshing = false;
    }
  }

  const resolvedRequest = await promiseFailedRequest;
  failedRequestsQueue.delete(failedRequest);

  return resolvedRequest;
};
