import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

export const ContainerReply = styled.div`
  max-width: 100vw;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  //transition to visible reply
  transition: all ease 0.3s;
  padding: 0.5rem;
  border-left: 4px solid ${colors.gray200};
  border-radius: ${radii.sm};
  margin-bottom: 0.5rem;
  background-color: rgba(37, 40, 45, 0.04);
`;

export const ButtonCloseReply = styled.button`
  background-color: ${colors.gray200};
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  &:hover {
    background-color: ${colors.blue300};
  }
`;

export const CloseIconReply = styled(MdClose)`
  width: 20px;
  height: 20px;
  color: ${colors.white300};
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 0.5rem;
  overflow: hidden;
  p {
    max-height: calc(2 * 1.2em);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ReplyText = styled.p`
  color: ${colors.gray300};
  font-size: 0.875rem;
`;

export const UserNameReply = styled.p`
  color: ${colors.gray300};
  font-size: 0.875rem;
  font-weight: 600;
`;
