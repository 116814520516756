import { type ReactNode } from 'react';

import { Container, Content, Label } from './style';

export interface ICheckboxContentProps {
  children: ReactNode;
  htmlFor: string;
  label: string;
  dataCy?: string;
}

export const CheckboxContent = ({
  children,
  htmlFor,
  label,
  dataCy = 'label-checkbox',
}: ICheckboxContentProps): JSX.Element => {
  return (
    <Container data-cy="checkbox-container">
      <Content>{children}</Content>
      <Label data-cy={dataCy} htmlFor={htmlFor}>
        {label}
      </Label>
    </Container>
  );
};
