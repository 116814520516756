import { CheckboxContent, type ICheckboxContentProps } from './CheckboxContent';
import { FormContent, type IFormContentProps } from './FormContent';
import { FormGrid, type IFormGridProps } from './FormGrid';
import {
  FormInputDate,
  FormInputText,
  type IFormInputDateProps,
  type IFormInputTextProps,
} from './FormInput';
import { FormInputActionBase } from './FormInputActions';
import { FormInputContent } from './FormInputContent';
import { Provider } from './FormProvider';
import { type IFormProvider } from './FormProvider';
import { FormRoot } from './FormRoot';
import { type IFormRootsProps } from './FormRoot';

export const FormBase = {
  Root: FormRoot as React.FC<IFormRootsProps>,
  Content: FormContent as React.FC<IFormContentProps>,
  Grid: FormGrid as React.FC<IFormGridProps>,
  CheckboxContent: CheckboxContent as React.FC<ICheckboxContentProps>,
  InputActions: FormInputActionBase,
  InputContent: FormInputContent,
  InputText: FormInputText as React.FC<IFormInputTextProps>,
  InputDate: FormInputDate as React.FC<IFormInputDateProps>,
  Provider: Provider as React.FC<IFormProvider>,
};
