import { css } from 'styled-components';

import { colors } from './colors';
import { radii } from './radii';

export const scrollbar = css`
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${colors.scrollbar};
    border: 2px solid transparent;
    border-radius: ${radii.lg};
    min-height: 45px;
    &:hover {
      background-color: ${colors.scrollbarHover};
    }
  }
  &:not(:hover) {
    &::-webkit-scrollbar {
      visibility: hidden;
    }
  }
`;
