import styled, { css } from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

import { LazyImage } from '../LazyImage';
import { TableCell } from '../TableCell';

interface IContainerProps {
  center?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  width: 100%;
  overflow: hidden;
  ${({ center = false }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;
interface ILazyImageProps {
  type?: 'bigLogo' | 'icon' | 'image';
}

export const ImageContainer = styled.div<ILazyImageProps>`
  align-items: center;
  color: ${colors.textLight};
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  width: 4rem;

  ${({ type }) =>
    type === 'bigLogo' &&
    css`
      height: 60px;
      width: 15rem;
    `}
`;

export const InitialContainer = styled.div<ILazyImageProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${colors.gray100};
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  ${({ type }) =>
    type === 'bigLogo' &&
    css`
      height: 40px;
      width: 10rem;
    `}
`;

export const InitialText = styled.h1`
  color: ${colors.text};
`;

export const LazyImageCustom = styled(LazyImage)<ILazyImageProps>`
  border-radius: ${({ type }) => (type === 'icon' ? ' 50%' : 'none')};
  height: 40px;
  width: 40px;
  object-fit: contain;
  ${({ type }) =>
    type === 'bigLogo' &&
    css`
      height: 40px;
      width: 10rem;
    `}
`;

export const TableCellCustom = styled(TableCell)`
  max-width: calc(100% - 4rem);
  width: auto;
`;
