import { type AxiosInstance } from 'axios';
import { produce } from 'immer';
import {
  type Dispatch,
  type MouseEvent,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { DefaultDescription, LIMIT_PAGE, StorageKey } from '../constants';
import { useToast } from '../hooks';
import {
  type IApiThrowsError,
  type IChatTopicResponseDTO,
  type IFrontendUrls,
  type IMessageDTO,
  type INotificationDTO,
  type IPaginationMetaProps,
  type IRedirectNotification,
  NotificationService,
  NotificationType,
} from '../services';
import {
  Logger,
  getRouteFrom,
  getStorage,
  removeStorage,
  setStorage,
} from '../utils';

export interface INotificationContextProps<T = any> {
  notifications: INotificationDTO[];
  notificationsFiltered: INotificationDTO[];
  setNotificationsFiltered: Dispatch<SetStateAction<INotificationDTO[]>>;
  showOnlyUnread: boolean;
  setShowOnlyUnread: Dispatch<SetStateAction<boolean>>;
  showNotificationBadge: boolean;
  setShowNotificationBadge: Dispatch<SetStateAction<boolean>>;
  loadingListNotifications: boolean;
  loadingToggleNotificationId: number | null;
  loadingMarkAllNotifications: boolean;
  hubUrl: string | null;
  setHubUrl: Dispatch<SetStateAction<string | null>>;
  topics: Record<string, string> | null;
  setTopics: Dispatch<SetStateAction<Record<string, string> | null>>;
  showMarkAllButton: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  paginationMeta: IPaginationMetaProps | null;
  clientIdSelected: number | null;
  setClientIdSelected: Dispatch<SetStateAction<number | null>>;
  getHubUrl: () => Promise<void>;
  listNextNotifications: (currentPage?: number, limit?: number) => void;
  listNotifications: (page?: number, limit?: number) => void;
  handleOnMessageReceived: (e: MessageEvent) => Promise<void>;
  handleMarkAllAsRead: () => void;
  handleClickNotification: (
    notification: INotificationDTO,
    clientId?: number,
    selectedByClientId?: (id: string) => Promise<T>
  ) => Promise<void>;
  handleToggleStatus: (e: MouseEvent<HTMLButtonElement>, id: number) => void;
  handleOpenChange: (value: boolean) => void;
}

export const NotificationContext = createContext<INotificationContextProps>(
  {} as INotificationContextProps
);

interface INotificationProps {
  children: ReactNode;
  axiosInstance: AxiosInstance;
  frontendUrls?: Partial<IFrontendUrls>;
  repository: 'admin' | 'client' | 'edge';
  redirectNotification: IRedirectNotification[];
}

export const NotificationProvider = ({
  children,
  axiosInstance,
  repository,
  redirectNotification,
  frontendUrls = {
    edgeRoute: 'http://localhost:5173',
    adminRoute: 'http://localhost:5174',
    clientRoute: 'http://localhost:5175',
  },
}: INotificationProps): JSX.Element => {
  const notificationService = useMemo(
    () =>
      new NotificationService(
        axiosInstance,
        redirectNotification,
        repository,
        frontendUrls
      ),
    [axiosInstance, frontendUrls, redirectNotification, repository]
  );

  const [showOnlyUnread, setShowOnlyUnread] = useState(false);
  const [notifications, setNotifications] = useState<INotificationDTO[]>([]);
  const [notificationsFiltered, setNotificationsFiltered] = useState<
    INotificationDTO[]
  >([]);
  const [loadingListNotifications, setLoadingListNotifications] =
    useState(false);
  const [loadingToggleNotificationId, setLoadingToggleNotificationId] =
    useState<number | null>(null);
  const [loadingMarkAllNotifications, setLoadingMarkAllNotifications] =
    useState(false);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showNotificationBadge, setShowNotificationBadge] = useState(false);
  const [hubUrl, setHubUrl] = useState<string | null>(null);
  const [topics, setTopics] = useState<Record<string, string> | null>(null);
  const [clientIdSelected, setClientIdSelected] = useState<number | null>(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { addToast } = useToast();

  const paginationMetaRef = useRef(paginationMeta);

  const showMarkAllButton = useMemo(() => {
    return notifications.some((notification) => {
      return !notification.read;
    });
  }, [notifications]);

  useEffect(() => {
    paginationMetaRef.current = paginationMeta;
  }, [paginationMeta]);

  const getHubUrl = useCallback(async () => {
    await notificationService.getHubUrl().then(({ url, topics }) => {
      setHubUrl(url);
      setTopics(topics);
    });

    const unreadNotification = getStorage<boolean | null>(
      StorageKey.UNREAD_NOTIFICATION
    );
    if (unreadNotification === true) {
      setShowNotificationBadge(true);
    }
  }, [notificationService]);

  const listNotifications = useCallback(
    (page = 1, limit = 10) => {
      setLoadingListNotifications(true);
      notificationService
        .getNotificationsByUser(page, limit)
        .then(({ data, meta }) => {
          setNotifications(
            page !== 1
              ? produce((draft) => {
                  draft.push(...data);
                })
              : data
          );
          setPaginationMeta(meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.error('error: ', error);
        })
        .finally(() => {
          setLoadingListNotifications(false);
        });
    },
    [notificationService]
  );

  const handleOnMessageReceived = useCallback(
    async (e: MessageEvent) => {
      const parsedData = JSON.parse(e.data as string) as IChatTopicResponseDTO;
      let parsedPayload = JSON.parse(parsedData.payload) as INotificationDTO;
      const message = JSON.parse(
        parsedPayload.jsonSerializedTarget
      ) as IMessageDTO;

      const messageClientId = message.clientChat.client.id;
      const currentPage = pathname.split('/').pop();
      const isMessagePage = currentPage === 'messages';
      const shouldMarkAsRead =
        repository !== 'client'
          ? messageClientId === clientIdSelected && isMessagePage
          : isMessagePage;

      if (shouldMarkAsRead) {
        try {
          const response =
            await notificationService.toggleNotificationReadStatus([
              parsedPayload.id,
            ]);
          parsedPayload = response[0];
        } catch (error) {
          Logger.debug('error: ', error);
        }
      } else {
        setShowNotificationBadge(true);
        setStorage(StorageKey.UNREAD_NOTIFICATION, true);
      }

      setNotifications(
        produce((draft) => {
          if (
            draft.length >= LIMIT_PAGE &&
            paginationMetaRef.current?.page !==
              paginationMetaRef.current?.total_pages
          ) {
            draft.pop();
          }

          draft.unshift(parsedPayload);
        })
      );

      setPaginationMeta(
        produce((draft) => {
          if (draft?.total !== undefined) {
            draft.total += 1;
          }
        })
      );
    },
    [pathname, clientIdSelected]
  );

  const listNextNotifications = useCallback(
    (currentPage = paginationMeta?.page ?? 1, limit?: number) => {
      const page =
        currentPage === paginationMeta?.total_pages
          ? currentPage
          : currentPage + 1;
      listNotifications(page, limit);
    },
    [listNotifications, paginationMeta]
  );

  const handleMarkAllAsRead = useCallback(() => {
    setLoadingMarkAllNotifications(true);

    const notificationIds = notifications
      .filter((notification) => !notification.read)
      .map((notification) => notification.id);

    notificationService
      .toggleNotificationReadStatus(notificationIds)
      .then((data) => {
        const result = notifications.map((notification) => {
          const item = data.find((dataItem) => dataItem.id === notification.id);
          return item ?? notification;
        });

        setNotifications(result);
      })
      .catch((error: IApiThrowsError) => {
        Logger.error('error: ', error);
      })
      .finally(() => {
        setLoadingMarkAllNotifications(false);
      });
  }, [notifications]);

  const redirectToScreen = useCallback(
    async <T,>(
      notification: INotificationDTO,
      clientId?: number,
      selectedByClientId?: (id: string) => Promise<T>
    ) => {
      if (repository === 'client') {
        const page = redirectNotification.find(
          (item) => item.type === notification.type
        );
        if (!page?.url) return;

        navigate(page?.url.path);
        setIsOpen(false);
      }

      let messageClientId = 0;
      if (notification.type === NotificationType.CHAT_MESSAGE) {
        const message = JSON.parse(
          notification.jsonSerializedTarget
        ) as IMessageDTO;
        messageClientId = message.clientChat.client.id;
      }
      if (messageClientId !== clientId && selectedByClientId) {
        await selectedByClientId(String(messageClientId)).catch(() => {
          addToast({
            title: 'Error on selecting client',
            description: DefaultDescription,
            styleType: 'error',
            dataCy: 'select-client-error-toast',
          });
        });
        return;
      }

      if (clientId) {
        const page = redirectNotification.find(
          (item) => item.type === notification.type
        );
        if (!page?.url) return;
        const MessagesRoute = getRouteFrom(page.url);
        navigate(
          generatePath(MessagesRoute, {
            companyId: clientId,
          })
        );
      }
      setIsOpen(false);
    },
    [addToast, navigate, redirectNotification, repository]
  );

  const handleClickNotification = useCallback(
    async <T,>(
      notification: INotificationDTO,
      clientId?: number,
      selectedByClientId?: (id: string) => Promise<T>
    ) => {
      if (!notification.read) {
        const data = await notificationService.toggleNotificationReadStatus([
          notification.id,
        ]);
        const result = data[0];
        const index = notifications.findIndex(({ id }) => id === result.id);
        if (index !== -1) {
          setNotifications(
            produce((draft) => {
              draft.splice(index, 1, result);
            })
          );
        }
      }
      void redirectToScreen(notification, clientId, selectedByClientId);
    },
    [redirectToScreen, notificationService, notifications]
  );

  const handleToggleStatus = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
      e.stopPropagation();

      setLoadingToggleNotificationId(id);
      notificationService
        .toggleNotificationReadStatus([id])
        .then((data) => {
          const result = data[0];
          const index = notifications.findIndex(({ id }) => id === result.id);
          if (index !== -1) {
            setNotifications(
              produce((draft) => {
                draft.splice(index, 1, result);
              })
            );
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.error('error: ', error);
        })
        .finally(() => {
          setLoadingToggleNotificationId(null);
        });
    },
    [notifications]
  );

  const handleOpenChange = useCallback((value: boolean) => {
    setIsOpen(value);
    if (value) {
      setShowNotificationBadge(false);
      removeStorage(StorageKey.UNREAD_NOTIFICATION);
    }
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notificationsFiltered,
        setNotificationsFiltered,
        showOnlyUnread,
        setShowOnlyUnread,
        showNotificationBadge,
        setShowNotificationBadge,
        loadingListNotifications,
        loadingToggleNotificationId,
        loadingMarkAllNotifications,
        hubUrl,
        setHubUrl,
        topics,
        setTopics,
        showMarkAllButton,
        isOpen,
        setIsOpen,
        paginationMeta,
        clientIdSelected,
        setClientIdSelected,
        getHubUrl,
        listNextNotifications,
        listNotifications,
        handleOnMessageReceived,
        handleMarkAllAsRead,
        handleClickNotification,
        handleToggleStatus,
        handleOpenChange,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
