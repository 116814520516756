import {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios';
import {
  type ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  type IFrontendUrls,
  type IRole,
  type IRoutePath,
  type IThrowsError,
  Logger,
  getRouteFrom,
  getStorage,
  removeStorage,
  setStorage,
  translateUserRoleInRole,
  useToast,
} from '@gbs-monorepo-packages/common';

import {
  AuthStorageKey,
  authBroadcastChannelEvents,
  authBroadcastChannelName,
} from '../constants';
import {
  AuthService,
  type IGetManagersProps,
  type IGetUsersByRolesProps,
  type IPaginationManagersDTO,
  type IPaginationUsersByRolesDTO,
} from '../services';
import type * as Auth from '../services/auth';

export interface IRedirectOptions {
  to: string;
  replace?: boolean;
}

export interface ISignIn extends Auth.ISignInCredentialsDTO {
  redirect?: IRedirectOptions;
}

export interface IAuthContextData {
  forgotPassword: (credentials: Auth.IForgotCredentialsDTO) => Promise<void>;
  isAuthenticated: boolean;
  resetPassword: (
    credentials: Auth.IResetPasswordCredentialsDTO
  ) => Promise<void>;
  signIn: (credentials: ISignIn) => Promise<void>;
  syncUserData: (user: Auth.IUserDTO) => void;
  signOut: () => Promise<void>;
  user: Auth.IUserDTO | null;
  clientIdByUser: number;
  getCurrentRole: (userRoles: string[]) => IRole | null;
  saveUser: (
    user: Auth.IUserDTO,
    redirect?: IRedirectOptions | null,
    initialAccess?: boolean
  ) => void;
  getManagers: (
    { page, limit, filter, all }: IGetManagersProps,
    config?: AxiosRequestConfig
  ) => Promise<IPaginationManagersDTO>;
  usersByRoles: (
    { roles, page, limit, filter, all, clientId }: IGetUsersByRolesProps,
    config?: AxiosRequestConfig
  ) => Promise<IPaginationUsersByRolesDTO>;
}

interface IAuthProps {
  authorizationRoute: IRoutePath;
  axiosInstance: AxiosInstance;
  children: ReactNode;
  customUrls?: Partial<Auth.IAuthUrls>;
  mainContentRoute: IRoutePath;
  frontendUrls?: Partial<IFrontendUrls>;
}

interface ILocation extends Location {
  state: { from?: string } | null;
}

export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData
);

const authChannel = new BroadcastChannel(authBroadcastChannelName);
const edgeRoute: string =
  import.meta.env.VITE_EDGE_URL ?? 'http://localhost:5173';
const adminRoute: string =
  import.meta.env.VITE_ADMIN_URL ?? 'http://localhost:5174';
const clientRoute: string =
  import.meta.env.VITE_CLIENT_URL ?? 'http://localhost:5175';

export const AuthProvider = ({
  authorizationRoute,
  axiosInstance,
  children,
  customUrls,
  mainContentRoute,
  frontendUrls = {
    edgeRoute,
    adminRoute,
    clientRoute,
  },
}: IAuthProps): JSX.Element => {
  const [user, setUser] = useState<Auth.IUserDTO | null>(
    getStorage<Auth.IUserDTO>(AuthStorageKey.USER)
  );
  const isAuthenticated = !!user;
  const navigate = useNavigate();
  const { pathname, state = null }: Partial<ILocation> = useLocation();

  const { addToast } = useToast();

  const clientIdByUser: number = useMemo(() => {
    return user?.companyId ?? 0;
  }, [user]);

  const saveUser = useCallback(
    (
      user: Auth.IUserDTO,
      redirect?: IRedirectOptions | null,
      initialAccess?: boolean
    ) => {
      setUser(user);
      setStorage<Auth.IUserDTO>(AuthStorageKey.USER, user);

      const navigationState =
        initialAccess === true
          ? { ...state, initialAccess: true }
          : state ?? null;

      if (redirect === undefined) {
        navigate(getRouteFrom(mainContentRoute), {
          replace: true,
          state: navigationState,
        });
      } else if (redirect !== null) {
        const { to, replace } = redirect;
        navigate(to, {
          replace,
          state: navigationState,
        });
      }
    },
    [mainContentRoute, navigate, state]
  );

  const authService = useMemo(
    () => new AuthService(axiosInstance, customUrls, frontendUrls),
    [axiosInstance, customUrls, frontendUrls]
  );

  const removeUser = useCallback(
    (clearStateFrom = false) => {
      setUser(null);
      removeStorage(AuthStorageKey.USER);

      const navigationState = clearStateFrom ? null : { from: pathname };

      navigate(getRouteFrom(authorizationRoute), {
        replace: true,
        state: navigationState,
      });
    },
    [navigate, authorizationRoute, pathname]
  );

  const forgotPassword = useCallback(
    async ({ email }: Auth.IForgotCredentialsDTO) => {
      await authService.forgotPassword({ email }).catch((err: IThrowsError) => {
        throw err;
      });
    },
    [authService]
  );

  const resetPassword = useCallback(
    async ({ email, password }: Auth.IResetPasswordCredentialsDTO) => {
      await authService
        .resetPassword({ email, password })
        .catch((err: IThrowsError) => {
          throw err;
        });
    },
    [authService]
  );

  const signIn = useCallback(
    async ({ email, password, redirect }: ISignIn) => {
      const { data: user, token } = await authService
        .signIn({ email, password })
        .catch((err: AxiosError<Auth.IResponseWithCode>) => {
          throw err;
        });

      if (
        redirect === undefined ||
        !(window.location.origin === authService.getEdgeRoute())
      ) {
        const redirectPortal = authService.getRedirectPortal(user, token);
        if (redirectPortal) {
          window.location.assign(redirectPortal);
          return;
        }
      }

      localStorage.setItem('JWT_TOKEN', token);
      saveUser(user, redirect, true);
    },
    [authService, saveUser]
  );

  const syncUserData = useCallback(
    (user: Auth.IUserDTO) => {
      saveUser(user, null);
    },
    [saveUser]
  );

  const signOut = useCallback(async () => {
    try {
      await authService.signOut();

      authChannel.postMessage(authBroadcastChannelEvents.SignOut);
      removeUser(true);
    } catch (err) {
      Logger.debug('err:  ', err);
    }
  }, [authService, removeUser]);

  const getManagers = useCallback(
    async (
      { page, limit, filter, all = false }: IGetManagersProps,
      config?: AxiosRequestConfig
    ): Promise<IPaginationManagersDTO> => {
      return await authService.getManagers(
        { page, limit, filter, all },
        config
      );
    },
    [authService]
  );

  const usersByRoles = useCallback(
    async (
      {
        roles,
        page,
        limit,
        filter,
        all = false,
        clientId,
      }: IGetUsersByRolesProps,
      config?: AxiosRequestConfig
    ): Promise<IPaginationUsersByRolesDTO> => {
      return await authService.usersByRoles(
        { roles, page, limit, filter, all, clientId },
        config
      );
    },
    [authService]
  );

  const getCurrentRole = (userRoles: string[]): IRole | null => {
    let currentRole = null;
    for (const value of userRoles.values()) {
      const userRoleAux = translateUserRoleInRole[value] ?? null;
      // userRoleAux can be null
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!userRoleAux) {
        continue;
      }

      if (!currentRole) {
        currentRole = userRoleAux;
      } else if (currentRole.level < userRoleAux.level) {
        currentRole = userRoleAux;
      }
    }

    return currentRole;
  };

  useEffect(() => {
    authChannel.onmessage = (message: { data?: string }) => {
      switch (message.data) {
        case authBroadcastChannelEvents.SignOut:
          removeUser();
          break;
        case authBroadcastChannelEvents.NotifyForbidden:
          addToast({
            title: 'Action not allowed',
            description:
              "Ops, you don't have permission to execute this action.",
            styleType: 'error',
            dataCy: 'forbidden-error-toast',
          });
          break;
        default:
          break;
      }
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        forgotPassword,
        isAuthenticated,
        resetPassword,
        signIn,
        syncUserData,
        signOut,
        user,
        clientIdByUser,
        getCurrentRole,
        saveUser,
        getManagers,
        usersByRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
