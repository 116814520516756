export const getStorage = <T = any>(
  key: string,
  defaultValue?: T,
  storage: Storage = localStorage
): NonNullable<T> | null => {
  const saved = storage.getItem(key);
  try {
    const initial = JSON.parse(saved ?? 'null') as T | null;
    return initial ?? defaultValue ?? null;
  } catch (e) {
    return defaultValue ?? null;
  }
};

export const setStorage = <T = any>(
  key: string,
  value: T,
  storage: Storage = localStorage
): void => {
  const valueStr = JSON.stringify(value);
  if (valueStr !== undefined) storage.setItem(key, valueStr);
};

export const removeStorage = (
  key: string,
  storage: Storage = localStorage
): void => {
  storage.removeItem(key);
};
