import { type DropzoneOptions, useDropzone } from 'react-dropzone';

import { Container, UploadIcon } from './styles';

export const FileDropzone = (props: DropzoneOptions): JSX.Element => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone(props);

  return (
    <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <UploadIcon />
      <p>Drag and Drop or Browse to upload</p>
    </Container>
  );
};
