import { MdClose } from 'react-icons/md';
import styled, { css, keyframes } from 'styled-components';

import {
  colors,
  radii,
  scrollbar,
  shadows,
} from '@gbs-monorepo-packages/styles';
import { Content, Overlay, Portal } from '@radix-ui/react-dialog';

const overlayShow = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const contentShow = keyframes`
  0% { opacity: 0; transform: 'translate(-50%, -48%) scale(.96)' };
  100% { opacity: 1; transform: 'translate(-50%, -50%) scale(1)' };
`;

interface IDialogOverlayProps {
  $zIndex?: number;
}

// prettier-ignore
export const DialogOverlay = styled(Overlay)<IDialogOverlayProps>`
  background-color: ${colors.blackTranslucent400};

  inset: 0;
  position: fixed;
  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `}

  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.55, 0.29, 0.34, 1.02);
  }
`;

export const DialogPortal = styled(Portal)``;

interface IDialogContentProps {
  $biggerModal?: boolean;
  $sideModal?: boolean;
  $zIndex?: number;
  $fullHeight?: boolean;
  $setWidth?: string;
  $height?: string;
  $fullWidth?: boolean;
}

export const DialogContent = styled(Content)<IDialogContentProps>`
  background-color: ${colors.white300};
  box-shadow: ${shadows.modal};
  max-width: ${({ $biggerModal = false, $fullWidth = false }) =>
    $fullWidth ? '90vw' : $biggerModal ? '600px' : '450px'};
  padding: ${({ $fullHeight = false }) => ($fullHeight ? '0 25px' : '25px')};
  position: fixed;
  width: 90vw;
  overflow: auto;
  max-height: ${({ $fullHeight = false }) => ($fullHeight ? '100vh' : '90vh')};
  flex-direction: column;
  display: flex;

  ${scrollbar}
  ${({ $setWidth }) =>
    $setWidth !== undefined &&
    css`
      max-width: ${$setWidth};
    `}


  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `}

    ${({ $height }) =>
    $height !== undefined &&
    css`
      min-height: ${$height};
    `}

  ${({ $sideModal = false }) =>
    $sideModal
      ? css`
          border-top-left-radius: ${radii.md};
          border-bottom-left-radius: ${radii.md};
          height: 100%;
          right: 0;
          top: 0;
        `
      : css`
          border-radius: ${radii.sm};
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        `};

  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.55, 0.29, 0.34, 1.02);
  }

  &:focus {
    outline: none;
  }
`;

export const CloseButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: 100%;
  color: ${colors.black300};
  display: inline-flex;
  font-family: inherit;
  height: 25px;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
  &:focus {
    box-shadow: ${shadows.modal};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

export const CloseIcon = styled(MdClose)``;
