import { type ReactNode, createContext, useCallback, useState } from 'react';

interface ILinkBreadcrumb {
  name: string;
  url: string;
}

export interface IBreadcrumbContextData {
  breadcrumbs: ILinkBreadcrumb[] | null;
  addBreadcrumb: (router: ILinkBreadcrumb) => void;
  removeBreadcrumb: (index: number) => void;
  clearBreadcrumbs: () => void;
}

interface IBreadCrumbProps {
  children: ReactNode;
}

export const BreadcrumbsContext = createContext<IBreadcrumbContextData>(
  {} as IBreadcrumbContextData
);

export const BreadcrumbsProvider = ({
  children,
}: IBreadCrumbProps): JSX.Element => {
  const [breadcrumbs, setBreadcrumbs] = useState<ILinkBreadcrumb[] | null>([]);

  const addBreadcrumb = useCallback((breadcrumb: ILinkBreadcrumb) => {
    setBreadcrumbs((prevBreadcrumbs: ILinkBreadcrumb[] | null) => {
      const existingBreadcrumb = prevBreadcrumbs?.find(
        (prevBreadcrumb) => prevBreadcrumb.url === breadcrumb.url
      );
      if (existingBreadcrumb) {
        return prevBreadcrumbs;
      }
      if (!prevBreadcrumbs) {
        return [breadcrumb];
      }
      return [...prevBreadcrumbs, breadcrumb];
    });
  }, []);

  const removeBreadcrumb = useCallback((index: number) => {
    setBreadcrumbs((prevBreadcrumbs: ILinkBreadcrumb[] | null) => {
      const newBreadcrumbs = prevBreadcrumbs
        ? prevBreadcrumbs.slice(0, index + 1)
        : null;
      return newBreadcrumbs;
    });
  }, []);

  const clearBreadcrumbs = useCallback(() => {
    setBreadcrumbs([]);
  }, []);

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        addBreadcrumb,
        removeBreadcrumb,
        clearBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};
