export const ABORT_REQUEST_REASON = 'AVOID_CONCURRENCY';

export interface IRequestAbortController extends AbortController {
  avoidConcurrency: boolean;
  getReason: () => any;
}

export const generateAbortRequest = (
  avoidConcurrency = false
): IRequestAbortController => {
  const controller = new AbortController();
  return {
    abort: (reason?: any): void => {
      controller.abort(reason);
    },
    avoidConcurrency,
    getReason: (): any => controller.signal.reason,
    signal: controller.signal,
  };
};

export const isAbortError = <T = any>(error: T): boolean =>
  error instanceof Error && error.name === 'CanceledError';

export const assertAbortReason = (
  controller: IRequestAbortController,
  reason: any
): boolean => controller.getReason() === reason;
