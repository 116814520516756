import { type ReactNode } from 'react';

import type * as RadioGroup from '@radix-ui/react-radio-group';

import { RadioGroupIndicator, RadioGroupItem, RadioGroupRoot } from './styles';

export interface IBaseRadioButtonProps extends RadioGroup.RadioGroupItemProps {
  dataCy?: string;
}

export const BaseRadioButton = ({
  dataCy = 'radio-button',
  ...props
}: IBaseRadioButtonProps): JSX.Element => {
  return (
    <RadioGroupItem {...props} data-cy={dataCy}>
      <RadioGroupIndicator />
    </RadioGroupItem>
  );
};

export interface IBaseRadioGroupProps extends RadioGroup.RadioGroupProps {
  children: ReactNode;
  dataCy?: string;
}

export const BaseRadioGroup = ({
  children,
  dataCy = 'radio-group',
  ...props
}: IBaseRadioGroupProps): JSX.Element => {
  return (
    <RadioGroupRoot {...props} data-cy={dataCy}>
      {children}
    </RadioGroupRoot>
  );
};

export const BaseRadioGroupIndicator = ({
  ...props
}: RadioGroup.RadioGroupIndicatorProps): JSX.Element => {
  return <RadioGroupIndicator {...props} />;
};
