import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

export interface ITableCellProps {
  readonly textAlign?: string;
}

export const TableCellContainer = styled.div<ITableCellProps>`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  justify-content: center;
  overflow: hidden;
  padding: 0.5rem;
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  vertical-align: -webkit-baseline-middle;
  width: 100%;
`;

export const Text = styled.p`
  color: ${colors.gray350};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TextLink = styled.a`
  color: ${colors.gray350};
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${colors.blue250};
    text-decoration: underline;
  }
`;

export const Subtitle = styled.p`
  color: ${colors.gray250};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
