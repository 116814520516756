const Colors = {
  black300: '#111213',
  blackTranslucent250: 'rgba(0, 0, 0, 0.250)',
  blackTranslucent400: 'rgba(0, 0, 0, 0.439)',

  blue50: '#b9d8ea',
  blue100: '#6dc5ea',
  blue200: '#3eb4e4',
  blue250: '#007bad',
  blue275: '#2F80ED',
  blue300: '#145091',
  blue400: '#00426b',
  blue500: '#2f80ed',
  blue600: '#436D9B',
  blueTranslucent200: 'rgba(62, 180, 228, 0.15)',

  gray100: '#d2dce7',
  gray200: '#c4c4c4',
  gray250: '#8a9099',
  gray300: '#6f7780',
  gray350: '#3f434a',
  gray400: '#414042',
  gray500: '#25282d',

  green150: '#8ec64d',
  green200: '#49c96d',
  green300: '#20a144',
  green400: '#0a8852',
  greenTranslucent200: 'rgba(73, 201, 109, 0.15)',

  lightGray50: '#f8f8f8',
  lightGray100: '#ededed',
  lightGray200: '#d9d9d9',
  lightGray300: '#c6c9cc',

  orange400: '#df5a29',
  orange500: '#c44829',
  orange600: '#833421',
  orangeTranslucent400: 'rgba(223, 90, 41, 0.15)',

  red200: '#e14d4e',
  red300: '#f00',

  white300: '#fff',
  whiteTranslucent100: 'rgba(255, 255, 255, 0.100)',

  yellow200: '#ffdb56',
} as const;

const BackgroundColor = {
  backgroundHoverLight: Colors.whiteTranslucent100,
  backgroundHover: '#25282d0a',
  background: '#f5f7fa',
  backgroundDarker: Colors.lightGray50,

  messageBackgroundMe: '#e8ebfa',
  messageBackgroundClient: '#f5f5f5',
  messageBackgroundAdmin: '#d8d8f0',
} as const;

const ExtraColors = {
  authPagesBackground: BackgroundColor.background,
  authPagesFieldBorder: '#4aaff9',
  authPagesLink: '#304ffd',

  button: Colors.blue400,
  buttonShadow: Colors.blackTranslucent400,

  divider: 'rgba(0, 0, 0, 0.1)',

  dropzoneBackground: Colors.lightGray50,
  dropzoneBorder: Colors.lightGray300,
  dropzoneBorderError: Colors.red300,
  dropzoneBorderFocus: Colors.blue250,
  dropzoneBorderSuccess: Colors.green200,
  dropzoneText: Colors.gray350,

  grapesHighlight: '#3b97e3',
  grapesPrimary: BackgroundColor.background,
  grapesSecondary: Colors.blue400,
  grapesThird: Colors.blue100,
  grapesFourth: Colors.blue200,

  headerText: Colors.gray350,

  fieldFocus: Colors.blue250,
  inputBorder: Colors.gray400,
  inputBorderLight: Colors.gray200,
  inputPlaceholder: Colors.gray200,
  labelText: Colors.gray300,

  sidebarBackground: Colors.white300,
  sidebarBackgroundActive: Colors.lightGray50,
  sidebarText: Colors.gray350,
  sidebarTextLight: Colors.gray250,

  statusError: Colors.orangeTranslucent400,
  statusTextError: Colors.orange500,

  statusInfo: Colors.blueTranslucent200,
  statusTextInfo: Colors.blue300,

  statusSuccess: Colors.greenTranslucent200,
  statusTextSuccess: Colors.green300,

  statusWarning: Colors.blueTranslucent200,
  statusTextWarning: Colors.orange500,

  text: Colors.gray350,
  textLight: Colors.gray250,

  toastError: Colors.orange500,
  toastTextError: Colors.white300,

  toastInfo: Colors.blue400,
  toastTextInfo: Colors.blue400,

  toastSuccess: Colors.green400,
  toastTextSuccess: Colors.white300,

  toastWarning: Colors.orange500,
  toastTextWarning: Colors.orange500,

  scrollbar: '#6665',
  scrollbarHover: '#9998',

  statusTextComplete: '#13dc28',
  statusTextInProgress: '#DC7413',
  statusTextNew: '#145091',
  statusTextLow: '#13dc28',
  statusTextMedium: '#F9C400',
  statusTextHigh: '#DC1313',
} as const;

export const colors = {
  ...Colors,
  ...BackgroundColor,
  ...ExtraColors,
};
