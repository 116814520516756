import { type ReactNode } from 'react';

import { Content } from './styles';

export interface IFormContentProps {
  children: ReactNode;
}

export const FormContent = ({ children }: IFormContentProps): JSX.Element => {
  return <Content data-cy="content">{children}</Content>;
};
