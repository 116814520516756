import { forwardRef, useMemo } from 'react';

import WordIcon from '../../assets/DOC-file-icon.svg';
import ImageIcon from '../../assets/JPG-file-icon.svg';
import PdfIcon from '../../assets/PDF-file-icon.svg';
import FileIconDefault from '../../assets/Unknown-file-icon.svg';
import ExcelIcon from '../../assets/XLS-file-icon.svg';
import ZipIcon from '../../assets/ZIP-file-icon.svg';
import { Logger } from '../../utils/logger';
import { type ILazyImageProps, LazyImage } from '../LazyImage';

export interface IFileIconProps extends Omit<ILazyImageProps, 'alt' | 'src'> {
  file: File;
}

export const FileIcon = forwardRef<HTMLImageElement, IFileIconProps>(
  // eslint-disable-next-line no-restricted-syntax
  function FileIcon({ file, ...props }, ref): JSX.Element {
    const { source, alt } = useMemo(() => {
      const extension = file.name.split('.').pop();
      Logger.debug('FileIcon', ['extension', extension]);
      switch (extension) {
        case 'pdf':
          return { source: PdfIcon, alt: 'pdf-icon' };
        case 'txt':
        case 'xls':
        case 'xlsx':
          return { source: ExcelIcon, alt: 'xls-icon' };
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'svg':
          return { source: ImageIcon, alt: 'image-icon' };
        case 'doc':
        case 'docx':
          return { source: WordIcon, alt: 'document-icon' };
        case 'zip':
        case 'rar':
        case '7z':
          return { source: ZipIcon, alt: 'archive-icon' };
        default:
          return { source: FileIconDefault, alt: 'file-icon' };
      }
    }, [file.name]);

    return (
      <LazyImage dataCy={alt} {...props} src={source} alt={alt} ref={ref} />
    );
  }
);
