import { getStorage, removeStorage, setStorage } from './localStorage';

export const getSessionStorage = <T = any>(
  key: string,
  defaultValue?: T
): NonNullable<T> | null => {
  return getStorage(key, defaultValue, sessionStorage);
};

export const setSessionStorage = <T = any>(key: string, value: T): void => {
  setStorage(key, value, sessionStorage);
};

export const removeSessionStorage = (key: string): void => {
  removeStorage(key, sessionStorage);
};
