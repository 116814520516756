import { forwardRef } from 'react';

import { Slot, type SlotProps } from '@radix-ui/react-slot';

export interface IBaseSlotProps extends SlotProps {}

export const BaseSlot = forwardRef<
  HTMLElement,
  IBaseSlotProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseSlot({ ...props }, ref): JSX.Element {
  return <Slot {...props} ref={ref} />;
});
