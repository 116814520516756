import type { ChangeEventHandler, InputHTMLAttributes } from 'react';

import { BaseTooltip } from '../BaseTooltip';
import {
  CloseIcon,
  InputSearch,
  SearchCloseButton,
  SearchFieldset,
  SearchIcon,
} from './styles';

export interface ISearchBarProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'value'
  > {
  search: string;
  placeholder?: string;
  loading?: boolean;
  dataCy?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClearInput?: React.MouseEventHandler<HTMLButtonElement> | null;
  id?: string;
}

export const SearchBar = ({
  search,
  placeholder = 'Search',
  dataCy = placeholder,
  loading = false,
  onChange,
  onClearInput = null,
  id = 'input-search',
  ...props
}: ISearchBarProps): JSX.Element => (
  <SearchFieldset>
    <SearchIcon />

    <InputSearch
      {...props}
      placeholder={placeholder}
      value={search}
      type="text"
      onChange={onChange}
      data-cy={dataCy}
      readOnly={loading}
      id={id}
    />

    {onClearInput && (
      <BaseTooltip message="Clear search" data-cy="tooltip-clear-search">
        <SearchCloseButton
          type="button"
          onClick={(event) => {
            if (!loading) onClearInput(event);
          }}
          $visible={search !== ''}
          data-cy="button-cancel-search"
        >
          <CloseIcon />
        </SearchCloseButton>
      </BaseTooltip>
    )}
  </SearchFieldset>
);
