import { compareDesc, parseJSON } from 'date-fns';

export const sortByStringAttribute = <
  K extends string,
  T extends Record<K, string>
>(
  a: T,
  b: T,
  attribute: K
): number => {
  return a[attribute].toUpperCase().localeCompare(b[attribute].toUpperCase());
};

export const sortByNumberAttribute = <
  K extends string,
  T extends Record<K, number>
>(
  a: T,
  b: T,
  attribute: K
): number => {
  return a[attribute] - b[attribute];
};

export const sortByDateAttribute = <
  K extends string,
  T extends Record<K, string>
>(
  a: T,
  b: T,
  attribute: K
): number => {
  return compareDesc(parseJSON(a[attribute]), parseJSON(b[attribute]));
};
