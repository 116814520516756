import { type IRole } from '../utils/translateUserRoleInRole';

export enum Roles {
  ADMIN = 'ROLE_ADMIN',
  BROKER_CONSULTANT = 'ROLE_BROKER_CONSULTANT',
  RENEWAL_COORDINATOR = 'ROLE_RENEWAL_COORDINATOR',
  RENEWAL_ANALYST = 'ROLE_RENEWAL_ANALYST',
  ACCOUNT_MANAGER = 'ROLE_ACCOUNT_MANAGER',
  CLIENT_MANAGER = 'ROLE_CLIENT_MANAGER',
  ACCOUNT_SUPPORT = 'ROLE_ACCOUNT_SUPPORT',
  CLIENT = 'ROLE_CLIENT',
  EMPLOYEE = 'ROLE_EMPLOYEE',
}

export const rolesWithLevel: IRole[] = [
  { key: Roles.ADMIN, value: 'Admin', level: 5 },
  { key: Roles.BROKER_CONSULTANT, value: 'Broker/Consultant', level: 4 },
  { key: Roles.RENEWAL_COORDINATOR, value: 'Renewal Coordinator', level: 4 },
  { key: Roles.RENEWAL_ANALYST, value: 'Renewal Analyst', level: 4 },
  { key: Roles.ACCOUNT_MANAGER, value: 'Account Manager', level: 4 },
  { key: Roles.CLIENT_MANAGER, value: 'Client Manager', level: 4 },
  { key: Roles.ACCOUNT_SUPPORT, value: 'Account Support', level: 4 },
  { key: Roles.CLIENT, value: 'Client', level: 3 },
  { key: Roles.EMPLOYEE, value: 'Employee', level: 1 },
];

export const clientAndEmployeeWithLevel: IRole[] = rolesWithLevel.slice(-2);

export const rolesWithoutAdminWithLevel: IRole[] = rolesWithLevel.slice(-8);

export const ManagerRoles: Roles[] = [
  Roles.BROKER_CONSULTANT,
  Roles.RENEWAL_COORDINATOR,
  Roles.RENEWAL_ANALYST,
  Roles.ACCOUNT_MANAGER,
  Roles.CLIENT_MANAGER,
  Roles.ACCOUNT_SUPPORT,
];

export const NotViewerUsers: Roles[] = [
  Roles.ADMIN,
  ...ManagerRoles,
  Roles.CLIENT,
];

export const HighUserRoles: Roles[] = [Roles.ADMIN, ...ManagerRoles];

export const ContactCardsRoles: Roles[] = [
  Roles.BROKER_CONSULTANT,
  Roles.CLIENT_MANAGER,
  Roles.ACCOUNT_MANAGER,
];
