import { type FormEvent, type MouseEventHandler, useMemo } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { BaseModal, type IBaseModalProps } from '../BaseModal';
import { BaseTooltip } from '../BaseTooltip';
import { FormBase } from '../Form';
import {
  Button,
  ButtonsContainer,
  ErrorMessage,
  ErrorMessageContainer,
  TextContent,
  Title,
} from './styles';

export interface IFormModalProps extends Partial<IBaseModalProps> {
  acceptText?: string;
  dataCy?: string;
  declineText?: string;
  disabled?: boolean;
  errorMessage?: string;
  loading?: boolean;
  mainText: string;
  formId?: string;
  acceptButtonTooltipText?: string | null;
  onAccept?: (event: FormEvent<HTMLFormElement>) => Promise<void> | void;
  onDecline?: MouseEventHandler<HTMLButtonElement>;
  themeStyle?: 'admin' | 'default';
}

export const FormModal = ({
  acceptText,
  children,
  dataCy = 'form-modal',
  declineText,
  disabled = false,
  errorMessage,
  loading = false,
  mainText,
  acceptButtonTooltipText = null,
  formId,
  onAccept,
  onDecline,
  themeStyle = 'default',
  ...props
}: IFormModalProps): JSX.Element => {
  const errors = useMemo<string[]>(() => {
    if (!errorMessage) return [];

    return errorMessage.split('\n');
  }, [errorMessage]);

  return (
    <BaseModal loading={loading} dataCy={dataCy} {...props}>
      <TextContent data-cy="text-container">
        <DialogPrimitive.Title asChild>
          <Title themeStyle={themeStyle} data-cy={mainText}>
            {mainText}
          </Title>
        </DialogPrimitive.Title>
      </TextContent>
      <FormBase.Root id={formId} onSubmit={onAccept}>
        {children}
        {errorMessage && (
          <ErrorMessageContainer data-cy="error-message">
            {errors.map((error) => (
              <ErrorMessage key={error}>{error}</ErrorMessage>
            ))}
          </ErrorMessageContainer>
        )}
        <FormBase.Content>
          <ButtonsContainer>
            {declineText && (
              <Button
                type="button"
                dataCy="button-cancel"
                styleType="outline"
                onClick={onDecline}
                disabled={loading}
              >
                {declineText}
              </Button>
            )}
            {acceptText &&
              (acceptButtonTooltipText ? (
                <BaseTooltip message={acceptButtonTooltipText}>
                  <Button
                    type="submit"
                    dataCy="button-add"
                    loading={loading}
                    disabled={disabled}
                  >
                    {acceptText}
                  </Button>
                </BaseTooltip>
              ) : (
                <Button
                  type="submit"
                  dataCy="button-add"
                  loading={loading}
                  disabled={disabled}
                >
                  {acceptText}
                </Button>
              ))}
          </ButtonsContainer>
        </FormBase.Content>
      </FormBase.Root>
    </BaseModal>
  );
};
