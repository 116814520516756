import { type CSSProperties } from 'react';
import InfiniteScroll, {
  type Props as InfiniteScrollProps,
} from 'react-infinite-scroll-component';

import { ListLoading } from '../ListLoading';
import { Container, Text, TextContainer } from './styles';

export interface IBaseInfiniteScrollProps
  extends Omit<InfiniteScrollProps, 'loader'> {
  loading?: boolean;
  endMessage?: string;
  dataCy?: string;
  scrollableTarget?: string;
}

export const BaseInfiniteScroll = ({
  loading = false,
  endMessage = 'You have seen it all',
  style,
  className,
  dataCy = 'infinite-scroll',
  inverse = false,
  scrollableTarget = 'container-pagination',
  ...props
}: IBaseInfiniteScrollProps): JSX.Element => {
  const baseStyle = { overflow: 'unset' };
  const styles: CSSProperties = inverse
    ? { ...baseStyle, display: 'flex', flexDirection: 'column-reverse' }
    : baseStyle;

  return (
    <Container
      style={style}
      className={className}
      id={scrollableTarget}
      data-cy={`${dataCy}-container`}
    >
      <InfiniteScroll
        data-cy={dataCy}
        style={styles}
        inverse={inverse}
        scrollableTarget={scrollableTarget}
        {...props}
        loader={loading && <ListLoading />}
        endMessage={
          <TextContainer data-cy="text-container">
            <Text data-cy={endMessage}>{endMessage}</Text>
          </TextContainer>
        }
      />
    </Container>
  );
};
