import { type ReactNode } from 'react';

import { type Roles } from '@gbs-monorepo-packages/common';

import { useCan } from '../../hooks/useCan';

interface ICanProps {
  alternativeChildren?: ReactNode;
  children: ReactNode;
  forceIgnore?: boolean;
  permissions?: string[];
  roles?: Roles[];
}

export const Can = ({
  alternativeChildren,
  children,
  forceIgnore,
  permissions,
  roles,
}: ICanProps): JSX.Element => {
  const userCanSeeComponents = useCan({ permissions, roles });
  return (
    <>
      {(forceIgnore ?? false) || (userCanSeeComponents ?? false)
        ? children
        : alternativeChildren}
    </>
  );
};
