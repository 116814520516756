import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import { type ITextStatusType } from '.';

interface ITextProps {
  readonly $type: ITextStatusType;
}

export const Text = styled.p<ITextProps>`
  border-radius: ${radii.md};
  padding: 0.5em 0.875em;

  ${({ $type }) => {
    switch ($type) {
      case 'success':
        return css`
          background-color: ${colors.statusSuccess};
          color: ${colors.statusTextSuccess};
        `;
      case 'accepted':
        return css`
          background-color: ${colors.statusSuccess};
          color: ${colors.statusTextSuccess};
        `;
      case 'error':
        return css`
          background-color: ${colors.statusError};
          color: ${colors.statusTextError};
        `;
      case 'warning':
        return css`
          background-color: ${colors.statusWarning};
          color: ${colors.statusTextWarning};
        `;
      case 'info':
        return css`
          background-color: ${colors.statusInfo};
          color: ${colors.statusTextInfo};
        `;
    }
  }}
`;
