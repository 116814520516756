import { type ReactNode, forwardRef } from 'react';

import {
  BaseInfiniteScroll,
  type IBaseInfiniteScrollProps,
} from '../BaseInfiniteScroll';
import { type IDisplayContentProps } from '../DisplayContent';
import {
  GroupContent,
  HeaderItemGrid,
  MainContent,
  SortedDownIcon,
  SortedUpIcon,
} from './styles';

export interface IColumn {
  id: string;
  name: string;
  textAlign?: string;
}

export interface ISort {
  name: string;
  direction: 'ASC' | 'DESC';
}

export interface ITableProps extends Partial<IBaseInfiniteScrollProps> {
  children?: ReactNode;
  columns: IColumn[];
  loading?: boolean;
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  endMessage?: string;
  noItensMessage?: string;
  themeStyle?: 'admin' | 'default';
  tableStyles?: React.CSSProperties;
  onClickHeader?: (column: IColumn) => void;
  columnSorted?: ISort | null;
}

export const Table = ({
  children,
  columns,
  loading = false,
  dataLength,
  next,
  hasMore,
  endMessage = 'You have seen it all',
  noItensMessage = 'No items found',
  themeStyle = 'default',
  tableStyles,
  onClickHeader,
  columnSorted,
  ...props
}: ITableProps): JSX.Element => {
  return (
    <BaseInfiniteScroll
      {...props}
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
      loading={loading}
      endMessage={dataLength ? endMessage : noItensMessage}
    >
      <MainContent
        data-cy="table-container"
        $columns={columns.length}
        $themeStyle={themeStyle}
        style={tableStyles}
      >
        {columns.map((column) => (
          <HeaderItemGrid
            data-cy="item-grid"
            key={column.id}
            style={{
              padding:
                column.name === ''
                  ? '2rem 2rem 0.75rem'
                  : '0.5rem 0.5rem 0.75rem',
              justifyContent: (column.textAlign ?? 'center') as
                | 'center'
                | 'end'
                | 'start',
              textAlign: (column.textAlign ?? 'center') as
                | 'center'
                | 'end'
                | 'start',
            }}
            onClick={() => onClickHeader?.(column)}
          >
            <p data-cy={`column-${column.name}`}>{column.name}</p>

            {columnSorted?.name === column.id &&
              columnSorted.direction === 'ASC' && <SortedUpIcon />}
            {columnSorted?.name === column.id &&
              columnSorted.direction === 'DESC' && <SortedDownIcon />}
          </HeaderItemGrid>
        ))}
        {children}
      </MainContent>
    </BaseInfiniteScroll>
  );
};

export interface ITableRowProps extends IDisplayContentProps {
  cursor?: string;
}

export const TableRow = forwardRef<HTMLDivElement, ITableRowProps>(
  // eslint-disable-next-line no-restricted-syntax
  function TableRow({ cursor = 'pointer', ...props }, ref): JSX.Element {
    return <GroupContent ref={ref} {...props} $cursor={cursor} />;
  }
);
