export enum TemplatesType {
  MY_TEMPLATES = 0,
  SHARE_TEMPLATES = 1,
  GLOBAL_TEMPLATES = 2,
  AUTOBUILD_TEMPLATES = 3,
  BUILD_SCRATCH = 4,
}

export const TemplateTypeIdList = [
  TemplatesType.MY_TEMPLATES,
  TemplatesType.SHARE_TEMPLATES,
  TemplatesType.GLOBAL_TEMPLATES,
  TemplatesType.AUTOBUILD_TEMPLATES,
  TemplatesType.BUILD_SCRATCH,
];

export interface ITemplateType {
  key: TemplatesType;
  value: string;
}

export const templatesTypes = [
  { key: TemplatesType.GLOBAL_TEMPLATES, value: 'Global Templates' },
  { key: TemplatesType.MY_TEMPLATES, value: 'My Templates' },
  { key: TemplatesType.SHARE_TEMPLATES, value: 'Shared Templates' },
  { key: TemplatesType.AUTOBUILD_TEMPLATES, value: 'Auto Build Templates' },
  { key: TemplatesType.BUILD_SCRATCH, value: 'Build From Scratch' },
];

export const groupsTypes = [
  { key: TemplatesType.GLOBAL_TEMPLATES, value: 'Global Course Templates' },
  { key: TemplatesType.MY_TEMPLATES, value: 'My Course Templates' },
  { key: TemplatesType.SHARE_TEMPLATES, value: 'Shared Course Templates' },
  {
    key: TemplatesType.AUTOBUILD_TEMPLATES,
    value: 'Auto Build Course Templates',
  },
];
