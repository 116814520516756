export const generateNegativeTimestamp = (): number => {
  const tempId = new Date().getTime();
  return tempId < 0 ? tempId : -tempId;
};

export interface IGenerateUrlSearchParamsProps {
  fromSearchParams?: URLSearchParams;
  key: string;
  value: string;
  removeEmpty?: boolean;
}
export const generateUrlSearchParams = ({
  fromSearchParams,
  key,
  removeEmpty = false,
  value,
}: IGenerateUrlSearchParamsProps): URLSearchParams => {
  const searchParams = fromSearchParams ?? new URLSearchParams();
  if (removeEmpty && !value) {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  return searchParams;
};
