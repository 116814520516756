import pathNode from 'path-browserify';

import { type IRoutePath } from '../constants/RoutePaths';

export const getRouteFrom = ({ prefixPath, path }: IRoutePath): string =>
  pathNode.join(prefixPath, path);

export const getRoutesFrom = (routePath: IRoutePath[]): string[] => {
  return routePath.map(({ prefixPath, path }) =>
    pathNode.join(prefixPath, path)
  );
};
