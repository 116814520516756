import styled from 'styled-components';

import { colors, scrollbar } from '@gbs-monorepo-packages/styles';

import { BaseLoading } from '../BaseLoading';

export const Container = styled.div`
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

  ${scrollbar}
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  margin-top: 1.5rem;
  width: 3.25rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const NoMemberContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 1rem;
`;

export const NoMemberText = styled.p`
  color: ${colors.textLight};
  font-size: 1rem;
  font-weight: 400;
`;

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 1rem;
`;

export const Text = styled.p`
  color: ${colors.textLight};
  font-size: 1rem;
  font-weight: 400;
`;
