import { type Roles } from '@gbs-monorepo-packages/common';

import { type IUserDTO } from '../services/auth';
import { validateUserPermissions } from '../utils/validateUserPermissions';
import { useAuth } from './useAuth';

export interface IUseCanParams {
  permissions?: string[];
  roles?: Roles[];
}

export const useCan = (
  { permissions, roles }: IUseCanParams,
  userToValidate: IUserDTO | null = null
): boolean | null => {
  const { user: userFromAuth } = useAuth();

  const user = userToValidate ?? userFromAuth;
  if (!user) {
    return null;
  }

  const userHasValidPermissions = validateUserPermissions({
    user,
    permissions,
    roles,
  });

  return userHasValidPermissions;
};
