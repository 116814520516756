import { type FormEvent, type FormHTMLAttributes, type ReactNode } from 'react';

import { Form } from './styles';

export interface IFormRootsProps extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => Promise<void> | void;
}

export const FormRoot = ({
  children,
  onSubmit,
  ...props
}: IFormRootsProps): JSX.Element => {
  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    await onSubmit?.(event);
  };

  return (
    <Form
      {...props}
      onSubmit={(e) => {
        void handleSubmit(e);
      }}
    >
      {children}
    </Form>
  );
};
