import { type ReactNode, createContext, useCallback, useState } from 'react';

import { StorageKey } from '../constants/Storage';
import { getStorage, setStorage } from '../utils/localStorage';

export interface ISidebarContextData {
  handleShrinkSidebar: (isShrunken: boolean) => void;
  isShrunken: boolean;
  toggleSidebar: () => void;
}

interface ISidebarProps {
  children: ReactNode;
}

export const SidebarContext = createContext<ISidebarContextData>(
  {} as ISidebarContextData
);

export const SidebarProvider = ({ children }: ISidebarProps): JSX.Element => {
  const [isShrunken, setIsShrunken] = useState(
    getStorage<boolean>(StorageKey.SIDEBAR) ?? false
  );

  const handleShrinkSidebar = useCallback((isShrunken: boolean) => {
    setIsShrunken(isShrunken);
    setStorage<boolean>(StorageKey.SIDEBAR, isShrunken);
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsShrunken(!isShrunken);
    setStorage<boolean>(StorageKey.SIDEBAR, !isShrunken);
  }, [isShrunken]);

  return (
    <SidebarContext.Provider
      value={{
        handleShrinkSidebar,
        isShrunken,
        toggleSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
