import React, { type ReactNode, forwardRef } from 'react';

import { DefaultButton, Loading, SlotButton } from './styles';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  children: ReactNode;
  dataCy?: string;
  loading?: boolean;
  noPaddingRight?: boolean;
  styleType?: 'accept' | 'admin' | 'decline' | 'default' | 'outline' | 'text';
  textTransform?: 'capitalize' | 'lowercase' | 'uppercase';
  fontWeight?: number;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  // eslint-disable-next-line no-restricted-syntax
  function Button(
    {
      asChild = false,
      children,
      dataCy = 'button',
      disabled = false,
      loading = false,
      noPaddingRight = false,
      styleType,
      textTransform = 'uppercase',
      fontWeight = 700,
      ...props
    },
    ref
  ): JSX.Element {
    const Comp = asChild ? SlotButton : DefaultButton;
    return (
      <Comp
        type={'button'}
        {...props}
        data-cy={dataCy}
        disabled={loading || disabled}
        noPaddingRight={noPaddingRight}
        ref={ref}
        styleType={styleType}
        textTransform={textTransform}
        fontWeight={fontWeight}
      >
        {loading ? <Loading /> : children}
      </Comp>
    );
  }
);
