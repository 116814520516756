import { type ReactNode } from 'react';

import { Container } from './styles';

export interface IMainContainerProps {
  children: ReactNode;
}

export const MainContainer = ({
  children,
}: IMainContainerProps): JSX.Element => {
  return <Container>{children}</Container>;
};
