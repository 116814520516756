import { FormProvider, type FormProviderProps } from 'react-hook-form';

export interface IFormProvider extends FormProviderProps<any, any> {}

export const Provider = ({
  children,
  ...formProps
}: IFormProvider): JSX.Element => {
  return (
    <FormProvider data-cy="formProvider" {...formProps}>
      {children}
    </FormProvider>
  );
};
