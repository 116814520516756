import { type ReactNode, createContext, useState } from 'react';

import { type IMessageDTO } from '../services';

export interface IMessagesContextData {
  selectReplyMessage: IMessageDTO | null;
  setSelectReplyMessage: (message: IMessageDTO | null) => void;
}

interface IMessageProps {
  children: ReactNode;
}

export const MessageContext = createContext<IMessagesContextData>(
  {} as IMessagesContextData
);

export const MessageProvider = ({ children }: IMessageProps): JSX.Element => {
  const [selectReplyMessage, setSelectReplyMessage] =
    useState<IMessageDTO | null>(null);

  return (
    <MessageContext.Provider
      value={{
        selectReplyMessage,
        setSelectReplyMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
