import { type HTMLAttributes } from 'react';

import { Loading, LoadingContainer } from './styles';

interface IListLoadingProps extends HTMLAttributes<HTMLDivElement> {
  dataCy?: string;
}

export const ListLoading = ({
  dataCy = 'loading-items-container',
  ...props
}: IListLoadingProps): JSX.Element => {
  return (
    <LoadingContainer {...props} data-cy={dataCy}>
      <Loading />
    </LoadingContainer>
  );
};
