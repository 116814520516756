import { type ButtonHTMLAttributes, type ElementType } from 'react';

import { Button } from './styles';

export interface IFormInputActionPropsBase
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ElementType;
  dataCy?: string;
}

export const FormInputActionBase = ({
  icon: Icon,
  dataCy,
  ...props
}: IFormInputActionPropsBase): JSX.Element => {
  return (
    <Button data-cy={dataCy} type="button" {...props}>
      <Icon />
    </Button>
  );
};
