import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

export const Button = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.labelText};
  display: inline-flex;
  font-size: 1rem;
  height: 2.5rem;
  justify-content: center;
  width: 2.75rem;
  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;
