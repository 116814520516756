import { useCallback, useState } from 'react';
import { type DropzoneOptions } from 'react-dropzone';

import { UnexpectedError } from '../../constants/ErrorMessages';
import { formatSize } from '../../utils/format';
import { FileDropzone } from '../FileDropzone';
import { FormBase } from '../Form';
import { FormModal, type IFormModalProps } from '../FormModal';
import {
  FileAcceptedIcon,
  FileContainer,
  FileDetails,
  FileDropzoneContainer,
  FileIconCustom,
  FileName,
  FileSize,
  FilesContainer,
} from './styles';

export interface IUploadFileModalProps
  extends Partial<Omit<IFormModalProps, 'onAccept' | 'onOpenChange'>> {
  dropzoneOptions?: DropzoneOptions;
  onAccept?: (uploaded: File[]) => Promise<void>;
  onDecline?: () => void;
  onOpenChange?: () => void;
  open: boolean;
  waitToOpen?: boolean;
}

export const UploadFileModal = ({
  children,
  dropzoneOptions,
  errorMessage,
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  zIndex,
  onOpenChange,
  ...props
}: IUploadFileModalProps): JSX.Element | null => {
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [errorOnAccept, setErrorOnAccept] = useState('');

  const resetForm = useCallback(() => {
    setFiles([]);
    setLoadingUploadFile(false);
    setErrorOnAccept('');
  }, []);

  const handleUploadFile = useCallback(() => {
    if (files.length) {
      setLoadingUploadFile(true);
      onAccept?.(files)
        .then(() => {
          resetForm();
        })
        .catch((error: Error) => {
          if (!(error instanceof Error)) {
            setErrorOnAccept(UnexpectedError);
            setLoadingUploadFile(false);
            return;
          }
          setErrorOnAccept(error.message);
          setLoadingUploadFile(false);
        });
    } else {
      setErrorOnAccept('No file selected');
    }
  }, [files, onAccept, resetForm]);

  const handleDeclineUploadFile = useCallback(() => {
    onDecline?.();
    resetForm();
    onOpenChange?.();
  }, [onDecline, onOpenChange, resetForm]);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      setErrorOnAccept('Invalid file');
      setFiles([]);
      return;
    }
    setErrorOnAccept('');
    setFiles(acceptedFiles);
  }, []);

  return !open ? null : (
    <FormModal
      loading={loadingUploadFile}
      dataCy="upload-file-modal"
      acceptText="Upload"
      mainText="Upload file"
      {...props}
      errorMessage={errorMessage ?? errorOnAccept}
      open={!waitToOpen}
      onAccept={handleUploadFile}
      onDecline={handleDeclineUploadFile}
      onOpenChange={handleDeclineUploadFile}
      zIndex={zIndex}
    >
      <FormBase.Content>
        {children}

        <FileDropzoneContainer>
          <FileDropzone
            maxFiles={1}
            onDrop={handleDrop}
            {...dropzoneOptions}
            disabled={props.disabled ?? loadingUploadFile}
          />
        </FileDropzoneContainer>

        {!!files.length && (
          <FilesContainer>
            {files.map((file) => {
              const { name, size } = file;
              return (
                <FileContainer key={name}>
                  <FileIconCustom file={file} dataCy={'logo-image'} />
                  <FileDetails>
                    <FileName>{name}</FileName>
                    <FileSize>{formatSize(size)}</FileSize>
                  </FileDetails>
                  <FileAcceptedIcon />
                </FileContainer>
              );
            })}
          </FilesContainer>
        )}
      </FormBase.Content>
    </FormModal>
  );
};
